import React from "react";
import "./Loader.css";
import { ReactTyped } from "react-typed";
import Amity from "../../../assets/Amity.png";
import { RightSection } from "../../EnvPageComponent/LoadingDetails";

const Loader = () => {
  return (
    <div className="loader_wrap">
      <img src={'https://t4.ftcdn.net/jpg/07/42/63/07/360_F_742630781_oYsaGN2Bw4VH5aRJ2QRmy8dkzZ3TzILH.webp'} alt="atsrverse" width={1920} height={1080} />
      <div className="loader_text">
        <ReactTyped
          strings={[
            "Prepare for a New Reality… Loading Now!",
            "Your Metaverse Journey is Almost Ready!",
            "Crafting Your Metaverse Experience… Please Wait!",
            "Almost There… Your Digital Realm is Loading!",
          ]}
          typeSpeed={90}
          loop
          backSpeed={20}
          showCursor={true}
          style={{
            color: "white",
            fontWeight: 400,
            fontSize: "16px",
            background: "#0000005e",
            padding: "5px",
            borderRadius: "10px",
          }}
        />
      </div>
      <div className={"loader_instructions"}>
        <div>
          <RightSection />
        </div>
      </div>
    </div>
  );
};

export default Loader;
