'use client';
import { createContext, useEffect, useState } from 'react';
import { fetchUserData } from '../services';

export const AppData = createContext();

const AppContext = ({ children }) => {
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [clickedEnv, setClickedEnv] = useState('');
  const [showChat, setShowChat] = useState(true);
  const [uploadModalData, setUploadModalData] = useState({});
  const [routeLoader, setRouteLoader] = useState(false);
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [accessToken, setAccessToken] = useState(false);
  const [userData, setUserData] = useState({ display_name: 'unlined_mule278' });
  const [kbdEventEnable, setKbdEventEnable] = useState(true);
  const [usernameChanged, setUsernameChanged] = useState(false);
  const [actionName, setActionName] = useState('');
  const [showAvatarPanel, setShowAvatarPanel] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [showUsersList, setShowUsersList] = useState(false);
  const [showVideoList, setShowVideoList] = useState(false);
  const [isUserJoined, setIsUserJoined] = useState(false);


  useEffect(() => {
    if (!accessToken && sessionStorage.getItem('token')) {
      setAccessToken(sessionStorage.getItem('token'));
    }
  }, [accessToken]);

  useEffect(() => {
    if ((!userData?._id && accessToken) || usernameChanged) {
      fetchUserData(
        res => {
          setUsernameChanged(false);
          setUserData(res?.user);
          setIsLoggedIn(true);
        },
        err => {
          console.log('Userdata fetching', err);
          sessionStorage.clear();
          window.location.reload();
        },
      );
    }
  }, [accessToken, userData, usernameChanged, clickedEnv]);

  const handleEnvOpen = id => {
    if (isLoggedIn) {
      setRouteLoader(true);
      // router.push(`/env/${id}`, { scroll: false });
    } else {
      setShowLoginModal(true);
      setClickedEnv(id);
    }
  };

  return (
    <AppData.Provider
      value={{
        showLoginModal,
        setShowLoginModal,
        showChat,
        handleEnvOpen,
        setClickedEnv,
        setShowChat,
        uploadModalData,
        setUploadModalData,
        routeLoader,
        setRouteLoader,
        accessToken,
        setAccessToken,
        showUsernameModal,
        setShowUsernameModal,
        userData,
        setUserData,
        usernameChanged,
        setUsernameChanged,
        kbdEventEnable,
        setKbdEventEnable,
        actionName,
        setActionName,
        showGalleryModal,
        setShowGalleryModal,
        showAvatarPanel,
        setShowAvatarPanel,
        userProfile,
        setUserProfile,
        showUsersList,
        setShowUsersList,
        showVideoList,
        setShowVideoList,
        isUserJoined,
        setIsUserJoined,
      }}
    >
      {children}
    </AppData.Provider>
  );
};

export default AppContext;
