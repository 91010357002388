import { useContext, useRef } from 'react';
import { SelfiIcon } from '../../../svgIcons';
import styles from './AvatarMenu.module.css';
import { AppData } from '../../../context';

const AvatarMenu = ({ sendMessage }) => {
  const { setShowAvatarPanel } = useContext(AppData);
  const buttonRef = useRef(null);

  const handleClick = () => {
    setShowAvatarPanel(true);
  };

  return (
    <></>
    // <div
    //   ref={buttonRef}
    //   className={styles.button_wrap}
    //   onClick={() => handleClick()}>
    //   <SelfiIcon />
    // </div>
  );
};

export default AvatarMenu;
