import { useEffect, RefObject } from 'react';

const useClickOutside = (ref, onClickOutside, extraField) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (
          !extraField ||
          (extraField?.current && !extraField.current.contains(event.target))
        ) {
          onClickOutside();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside, extraField]);
};

export default useClickOutside;
