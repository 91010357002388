import { useCallback, useRef, useState } from 'react';
import { GestureIcon, SelfiIcon } from '../../../svgIcons';
import InteractionMenu from '../../CommonComponent/InteractionMenu';
import KBDButton from '../../CommonComponent/KBDButton';
import Tooltip from '../../CommonComponent/Tooltip';
import styles from './SelfiMenu.module.css';
import useClickOutside from '../../../utils/hooks/useClickOutside';

const emotes = [
  {
    url: 'https://dd2cgqlmnwvp5.cloudfront.net/emote_previews/PopAndLock.webm',
    name: "Pop 'n Lock",
    id: 3,
  },
  {
    url: 'https://dd2cgqlmnwvp5.cloudfront.net/emote_previews/GetDown.webm',
    name: 'Get Down',
    id: 4,
  },
  {
    url: 'https://dd2cgqlmnwvp5.cloudfront.net/emote_previews/Clap.webm',
    name: 'Clap',
    id: 5,
  },
];

const SelfiMenu = ({ sendMessage }) => {
  const buttonRef = useRef(null);

  const handleClick = () => {
    sendMessage(
      'ReactUnityCommunicationManager',
      'GetReactData',
      JSON.stringify({
        event: 'selfie',
        data: {},
      }),
    );
  };
  return (
    // <div className={styles.menu_wrap}>
    //   <div className={styles.button_group}>
    <div
      ref={buttonRef}
      className={styles.button_wrap}
      onClick={() => handleClick()}
    >
      <SelfiIcon />
    </div>
    //   </div>

    // </div>
  );
};

export default SelfiMenu;
