import React, { useEffect, useState } from 'react';
import styles from './LoadingDetails.module.css';

let interval = undefined;

const LoadingDetails = ({ isLoading, loadingComplete }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (isLoading) {
      interval = setInterval(() => {
        setWidth(width =>
          width === 1 ? 1 : width + 0.1 > 0.8 ? 0.8 : width + 0.1,
        );
        if (width >= 0.8) {
          handleClearinterval(interval);
        }
      }, 2000);
    }
  }, [isLoading]);

  useEffect(() => {
    if (loadingComplete) {
      handleClearinterval(interval);
      setWidth(1);
    }
  }, [loadingComplete]);

  const handleClearinterval = timeInterval => {
    clearInterval(timeInterval);
  };

  return (
    <div className={styles.content_wrap}>
      <div className={styles.left_section}>
        <div className={styles.details_wrap}>
          <div className={styles.user_details}>
            <div>
              <img
                src={
                  'https://www.spatial.io/_next/image?url=https%3A%2F%2Fmodels.readyplayer.me%2F63adfb198d4fc7b44d52629d.png&w=640&q=75'
                }
                alt="avatar"
                width={32}
                height={32}
                style={{
                  backgroundColor: 'rgba(41, 121, 255, 1)',
                  borderRadius: 999,
                }}
              />
            </div>
            <div className={styles.sub_title}>Astraverse</div>
          </div>

          <div className={styles.title}>Mostly Only Up!</div>
          <div className={styles.description}>
            Mostly Only Up is a fun obby parkour game where the ultimate spooky
            escape awaits you. Jump to the highest heights of this eerie fantasy
            realm, navigating uncanny platforms and bone-chilling leaps. One
            misstep plunges you into the abyss. Only the bravest make it to the
            top, can you?
          </div>
        </div>
        <div>
          <div className={styles.wrapper}>
            <div className={styles.track} style={{ '--progress': width }}>
              <div className={styles.handle}></div>
            </div>
          </div>
        </div>
        <div className={styles.user_details}>
          <div className={styles.second_avatar}>
            <img
              src={
                'https://www.spatial.io/_next/image?url=https%3A%2F%2Fmodels.readyplayer.me%2F63adfb198d4fc7b44d52629d.png&w=640&q=75'
              }
              alt="avatar"
              width={16}
              height={16}
              style={{
                backgroundColor: 'rgba(41, 121, 255, 1)',
                borderRadius: 999,
              }}
            />
          </div>
          <div className={styles.community_guidelines}>
            Joining as Adventuring Woodpecker. Remember to follow our{' '}
            <a
              className={styles.link_text}
              target="_blank"
              rel="noopener noreferrer"
              href="/guidelines"
            >
              Community Guidelines
            </a>
          </div>
        </div>
      </div>
      <RightSection />
    </div>
  );
};

export const RightSection = () => {
  return (
    <div className={styles.right_section}>
      <div className={styles.section_wrap}>
        <div className={styles.wsad_keys}>
          <kbd className={`${styles.kbd_button} ${styles.w_key}`}>W</kbd>
          <kbd className={`${styles.kbd_button} ${styles.a_key}`}>A</kbd>
          <kbd className={`${styles.kbd_button} ${styles.s_key}`}>S</kbd>
          <kbd className={`${styles.kbd_button} ${styles.d_key}`}>D</kbd>
        </div>
        <span className={`${styles.kbd_text} ${styles.text1}`}>Move</span>
      </div>
      <div className={styles.section_wrap}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          className={styles.plus_icon}
        >
          <path
            fill="currentColor"
            d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
          ></path>
        </svg>
      </div>
      <div className={styles.section_wrap}>
        <kbd className={`${styles.kbd_button} ${styles.btn2}`}>Shift</kbd>
        <span className={`${styles.kbd_text} ${styles.text2}`}>
          Hold shift to run
        </span>
      </div>
      <div className={`${styles.section_wrap} ${styles.space_btn}`}>
        <div>
          <kbd className={`${styles.kbd_button} ${styles.btn3}`}>Spacebar</kbd>
          <span className={`${styles.kbd_text} ${styles.text3}`}>Jump</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingDetails;
