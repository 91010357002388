import React, { useContext, useEffect } from 'react';
import { AppData } from '../../../context';
import { AvaturnSDK } from '@avaturn/sdk';
import styles from './AvatarModel.module.css';
import { updateAvatarUrl } from '../../../services';
import { SUCCESS_CODE } from '../../../constant';

const AvatarModel = () => {
  const {
    setKbdEventEnable,
    userData,
    setUserData,
    showAvatarPanel,
    setShowAvatarPanel,
  } = useContext(AppData);

  useEffect(() => {
    const sdk = new AvaturnSDK();

    if ((userData.email && !userData.avatar_url) || showAvatarPanel) {
      setKbdEventEnable(true);
      const container = document.getElementById('avaturn-sdk-container');
      sdk
        .init(container, {
          url: 'https://astraverse.avaturn.dev/', // required
          iframeClassName: styles.iframe_wrap, // optional
        })
        .then(() => {
          sdk
            .on('export', data => {
              setShowAvatarPanel(false);
              console.log(data);
              setUserData({ ...userData, avatar_url: data.url });
              updateAvatarUrl(
                { avatar_url: data.url },
                res => {
                  if (res.status === SUCCESS_CODE) {
                    console.warn('env data', res);
                  }
                },
                error => {
                  console.log('error in avatar url update ', error);
                },
              );
              sdk.destroy();
            })
            .on('changeParam', data => {
              console.warn(`[callback] Set ${data.key} to ${data.value}`);
            });
        })
        .catch(reason => {
          // sdk.getBodyList().then(setBodyList);
          // sdk.getAssetList().then(list => setAssetList(list));
          console.log(`[Avaturn SDK Error]: ${reason}`);
        });
    }
    return () => {
      sdk.destroy();
    };
  }, [userData.avatar_url, userData.email, showAvatarPanel]);

  return (
    <>
      {((!userData.avatar_url && userData.email) || showAvatarPanel) && (
        <div
          className={styles.avaturn_sdk_container}
          id="avaturn-sdk-container"
        ></div>
      )}
    </>
  );
};

export default AvatarModel;
