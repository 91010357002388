import { useContext, useEffect } from 'react';
import { AppData } from '../../../context';
import { GESTURE } from '../../../constant';
import GestureMenu from './GestureMenu';

const CenterActionMenu = ({ sendMessage }) => {
  const { actionName, kbdEventEnable, setActionName } = useContext(AppData);

  // useEffect(() => {
  //   const handleKbdKeyClick = event => {
  //     if (event.key === 't' && kbdEventEnable) {
  //       if (actionName === GESTURE) {
  //         setActionName('');
  //       } else {
  //         setActionName(GESTURE);
  //       }
  //     }
  //   };

  //   window.addEventListener('keydown', handleKbdKeyClick);
  //   return () => {
  //     window.removeEventListener('keydown', handleKbdKeyClick);
  //   };
  // }, [setActionName, actionName, kbdEventEnable]);

  const handleClose = () => {
    setActionName('');
  };

  switch (actionName) {
    case GESTURE:
      return (
        <GestureMenu sendMessage={sendMessage} handleClose={handleClose} />
      );
    case '':
      return <></>;
  }
};

export default CenterActionMenu;
