import CustomModal from '../../CommonComponent/CustomModal';
import styles from './SelfiViewer.module.css';

const SelfiViewer = ({ onCloseClick, imgUrl }) => {
  return (
    <div className={styles.modal_wrap}>
      <CustomModal
        closeOutSideClick={true}
        contentClass={styles.content_wrap}
        iconClass={styles.close_icon}
        onCloseClick={onCloseClick}
      >
        <div className={styles.media_wrap}>
          <div className={styles.left_section}>
            <img
              width={1000}
              height={window.innerHeight - 100}
              src={'data:image/jpeg;base64,' + imgUrl}
              alt="User"
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SelfiViewer;
