'use client';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './UsernameModal.module.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { AppData } from '../../../context';
import { FetchingIcon, RandomizeIcon } from '../../../svgIcons';
import { SUCCESS_CODE } from '../../../constant';
import { toast } from 'react-toastify';
import { checkValidUsername, getUsername } from '../../../services';

const UsernameModal = () => {
  const { showUsernameModal, setShowUsernameModal, setUsernameChanged } =
    useContext(AppData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingUsername, setLoadingUsername] = useState(false);
  const [loadingDisplayName, setLoadingDisplayName] = useState(false);
  const [data, setData] = useState({ display_name: '', username: '' });
  const [showDisplayName, setShowDisplayName] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [errorField, setErrorField] = useState('');

  useEffect(() => {
    if (showUsernameModal) {
      setIsModalOpen(true);
      setShowUsernameModal(false);
    }
  }, [showUsernameModal, setShowUsernameModal]);

  const onCloseModal = () => {
    // setIsModalOpen(false);
    console.log('modal closed');
  };

  const getIcon = useCallback((loading, label) => {
    if (loading) {
      return (
        <>
          <FetchingIcon className={styles.fetch_icon} />
          {label}
        </>
      );
    }
    return (
      <>
        <RandomizeIcon />
        Randomize
      </>
    );
  }, []);

  const handleChange = (label, e) => {
    setData({ ...data, [label]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    let bodyData = data;
    if (!showDisplayName) {
      delete bodyData.display_name;
    }

    bodyData.policy_checked = termsChecked;
    checkValidUsername(
      bodyData,
      res => {
        if (res?.status === SUCCESS_CODE) {
          if (res.error) {
            setErrorField(res.error);
          } else {
            setUsernameChanged(true);
            setIsModalOpen(false);
          }
        }
      },
      (err) => {
        console.log('error in validating username ', err);
        toast.error(
          `We are facing issue in sever. Please try after some time`,
          {
            position: 'top-right',
            autoClose: 3000,
            theme: 'dark',
          },
        );
      },
    );
  };

  const handleCheckboxChange = () => {
    setShowDisplayName(!showDisplayName);
  };

  const handleGenerate = async label => {
    if (label === 'username') {
      setLoadingUsername(true);
    } else {
      setLoadingDisplayName(true);
    }
    await getUsername(
      res => {
        if (res.status === SUCCESS_CODE) {
          setData({ ...data, [label]: res.username });
        }
      },
      err => {
        console.log('error in fetching username ', err);
        toast.error(
          `We are facing issue in unique name fetching. Please enter unique name as your wish`,
          {
            position: 'top-right',
            autoClose: 3000,
            theme: 'dark',
          },
        );
      },
    );
    setLoadingUsername(false);
    setLoadingDisplayName(false);
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={onCloseModal}
        center
        showCloseIcon={false}
        classNames={{
          modal: styles.custom_modal,
          overlay: styles.modal_overlay,
        }}
      >
        <div className={styles.login_options}>
          <div className={styles.title}>Set up your account</div>

          <form onSubmit={e => handleSubmit(e)}>
            <div className={styles.label_wrap}>
              <div className={styles.label}>Username</div>
              <div
                className={styles.generate_btn}
                onClick={() => handleGenerate('username')}
              >
                {getIcon(loadingUsername, 'Checking username')}
              </div>
            </div>
            <input
              className={styles.input_field}
              value={data.username}
              onChange={e => handleChange('username', e)}
            />
            {errorField === 'username' && (
              <div className={styles.error_text}>Username is already taken</div>
            )}
            <div className={styles.checkbox_wrap}>
              <input
                className={styles.checkbox_field}
                type="checkbox"
                checked={!showDisplayName}
                onChange={handleCheckboxChange}
                required
              />
              <span className={styles.checkbox_label}>
                Use username as display name
              </span>
            </div>

            {showDisplayName && (
              <div className={styles.display_name_wrap}>
                <div className={styles.label_wrap}>
                  <div className={styles.label}>Display Name</div>
                  <div
                    className={styles.generate_btn}
                    onClick={() => handleGenerate('display_name')}
                  >
                    {getIcon(loadingDisplayName, 'Checking display name')}
                  </div>
                </div>
                <input
                  value={data.display_name}
                  className={styles.input_field}
                  onChange={e => handleChange('display_name', e)}
                  required
                />
                {errorField === 'display_name' && (
                  <div className={styles.error_text}>
                    Display name is already taken
                  </div>
                )}
              </div>
            )}

            <div className={`${styles.label} `}>Terms and Conditions</div>
            <div className={`${styles.checkbox_wrap} ${styles.terms_checkbox}`}>
              <input
                className={styles.checkbox_field}
                type="checkbox"
                value={termsChecked}
                onChange={() => setTermsChecked(!termsChecked)}
              />
              <span></span>
              <div className={styles.checkbox_label}>
                I have read and agree to the{' '}
               
                , and confirm I am at least 18 years old.
              </div>
            </div>
            <input
              className={styles.submit_btn}
              disabled={!termsChecked}
              type={'submit'}
              value="Next"
            />
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default UsernameModal;
