import React, { useContext, useEffect } from 'react';
import styles from './UserProfileCard.module.css';
import { CloseIcon } from '../../../svgIcons';
import { AppData } from '../../../context';

const UserProfileCard = () => {
  const { setUserProfile, userProfile } = useContext(AppData);

  const handleCloseClick = () => {
    setUserProfile({});
  };

  return (
    userProfile.name && (
      <div>
        <div className={styles.card}>
          <div className={styles.card_text}>
            <div className={styles.portada}></div>
            <div className={styles.title_total}>
              <div className={styles.title}>User details</div>
              <div className={styles.name}>Name: {userProfile.name}</div>

              <div className={styles.desc}>Email: {userProfile.email}</div>
              <button className={styles.close_icon} onClick={handleCloseClick}>
                <CloseIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default UserProfileCard;
