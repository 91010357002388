import React, { useEffect, useState } from 'react';
import styles from './InfoModel.module.css';
import { RightSection } from '../LoadingDetails/index';

const InfoModel = ({ setShowInfo }) => {
  useEffect(() => {
    setTimeout(() => {
      setShowInfo(false);
    }, 3000);
  }, []);

  return (
    <div className={styles.side_menu_wrap}>
      <RightSection />
    </div>
  );
};

export default InfoModel;
