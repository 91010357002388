import { useContext, useEffect, useRef, useState } from "react";
import { ExitIcon, PauseIcon, PlayIcon } from "../../../svgIcons";
import CustomModal from "../../CommonComponent/CustomModal";
import styles from "./VideoPlayer.module.css";
import { AppData } from "../../../context";

const VideoPlayer = ({ videoUrl, videoStatus, sendMessage }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const { userData } = useContext(AppData);
  const ref = useRef(null);
  const handleClick = (playing) => {
    if (playing) {
      ref.current.play();
    } else {
      ref.current.pause();
    }

    sendMessage(
      "ReactUnityCommunicationManager",
      "GetReactData",
      JSON.stringify({
        event: "amitystartconfvideo",
        data: {
          videourl: videoUrl,
          status: playing ? "play" : "start",
        },
      })
    );
    setIsPlaying(playing);
  };

  useEffect(() => {
    if (videoStatus === "pause") {
      ref.current.pause();
    } else {
      ref.current.play();
    }
  }, [videoStatus]);

  const handleExist = () => {
    sendMessage(
      "ReactUnityCommunicationManager",
      "GetReactData",
      JSON.stringify({
        event: "amitystartconfvideo",
        data: {
          videourl: videoUrl,
          status: "close",
        },
      })
    );
  };

  return (
    <div className={styles.modal_wrap}>
      <CustomModal
        closeOutSideClick={false}
        contentClass={styles.content_wrap}
        hideCloseIcon={true}
      >
        <div className={styles.media_wrap}>
          <video className={styles.video_wrap} ref={ref} autoPlay muted loop>
            <source src={videoUrl} type="video/webm" />
          </video>
          {userData.is_admin && (
            <div className={styles.button_group}>
              {!isPlaying ? (
                <div
                  className={styles.button_wrap}
                  onClick={() => handleClick(true)}
                >
                  <PlayIcon />
                </div>
              ) : (
                <div
                  className={styles.button_wrap}
                  onClick={() => handleClick(false)}
                >
                  <PauseIcon />
                </div>
              )}

              <div className={styles.button_wrap} onClick={handleExist}>
                <ExitIcon />
              </div>
            </div>
          )}
        </div>
      </CustomModal>
    </div>
  );
};

export default VideoPlayer;
