import axios from 'axios';
import { AUTHORITY_URL } from '../config';

export const getAccessToken = () => {
  let data = sessionStorage.getItem(`token`);
  let accessToken = '';
  if (data) {
    accessToken = 'Bearer ' + (data || '');
  }

  return accessToken;
};

export const checkEmail = async (body, callback, error) => {
  await axios
    .post(`${AUTHORITY_URL}auth/email-lookup`, body)
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const signUp = async (body, callback, error) => {
  await axios
    .post(`${AUTHORITY_URL}auth/register`, body)
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const signIn = async (body, callback, error) => {
  await axios
    .post(`${AUTHORITY_URL}auth/sign-in`, body)
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const confirmMail = async (body, callback, error) => {
  await axios
    .post(`${AUTHORITY_URL}auth/confirm-email`, body)
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const resendConfirmEmail = async (body, callback, error) => {
  await axios
    .post(`${AUTHORITY_URL}auth/resend-confirm-email`, body)
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const fetchUserData = async (callback, error) => {
  await axios({
    url: `${AUTHORITY_URL}user/me`,
    method: 'get',
    headers: {
      Authorization: getAccessToken(),
    },
  })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const getUsername = async (callback, error) => {
  await axios
    .get(`${AUTHORITY_URL}user/username`, {
      headers: {
        Authorization: getAccessToken(),
      },
    })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const checkValidUsername = async (body, callback, error) => {
  await axios
    .post(`${AUTHORITY_URL}user/valid-username`, body, {
      headers: {
        Authorization: getAccessToken(),
      },
    })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const resetPasswordMail = async (body, callback, error) => {
  await axios
    .post(`${AUTHORITY_URL}auth/forgot-password-sendmail`, body)
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const resetPassword = async (body, callback, error) => {
  await axios
    .post(`${AUTHORITY_URL}user/forgot-password`, body)
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const confirmUserEmail = async (body, callback, error) => {
  await axios
    .post(`${AUTHORITY_URL}user/me`, body, {
      headers: {
        Authorization: getAccessToken(),
      },
    })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const fetchData = async (body, callback, error) => {
  await axios
    .post(`${AUTHORITY_URL}auth/sign-in`, body, {
      headers: {
        Authorization: getAccessToken(),
      },
    })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const fetchAllEnvironments = async () => {
  try {
    const res = await axios.get(`${AUTHORITY_URL}environments?page=0&size=100`);
    const response = res?.data;
    if (response?.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    console.log('errr', err);
    return [];
  }
};

export const fetchAllEditableEnvironments = async () => {
  try {
    const res = await axios.get(
      `${AUTHORITY_URL}environments/editable?page=0&size=100`,
    );
    const response = res?.data;
    if (response?.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    console.log('errr', err);
    return [];
  }
};

export const getEnvironment = async (slug, callback, error) => {
  await axios
    .get(`${AUTHORITY_URL}environments/${slug}`, {
      headers: {
        Authorization: getAccessToken(),
      },
    })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const uploadFile = async (body, callback, error) => {
  await axios
    .post(`${AUTHORITY_URL}environments/files`, body, {
      headers: {
        Authorization: getAccessToken(),
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const addContent = async (body, callback, error) => {
  await axios
    .post(`${AUTHORITY_URL}content`, body, {
      headers: {
        Authorization: getAccessToken(),
      },
    })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const updateAvatarUrl = async (body, callback, error) => {
  await axios
    .put(`${AUTHORITY_URL}user/me`, body, {
      headers: {
        Authorization: getAccessToken(),
      },
    })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const uploadSelfi = async (body, callback, error) => {
  await axios
    .post(`${AUTHORITY_URL}user/image`, body, {
      headers: {
        Authorization: getAccessToken(),
      },
    })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const getUserSelfies = async (callback, error) => {
  await axios
    .get(`${AUTHORITY_URL}user/images`, {
      headers: {
        Authorization: getAccessToken(),
      },
    })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const getAllSelfies = async (callback, error) => {
  await axios
    .get(`${AUTHORITY_URL}images`, {
      headers: {
        Authorization: getAccessToken(),
      },
    })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const getUserById = async (id, callback, error) => {
  await axios
    .get(`${AUTHORITY_URL}user/${id}`, {
      headers: {
        Authorization: getAccessToken(),
      },
    })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};

export const getAgoraToken = async (channel, id, callback, error) => {
  await axios
    .get(`${AUTHORITY_URL}rtc/${channel}/publisher/uid/${id}`, {
      headers: {
        Authorization: getAccessToken(),
      },
    })
    .then(res => callback(res?.data || {}))
    .catch(err => error(err));
};
