import React, { useContext, useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import styles from './LoginModal2.module.css';
import { Modal } from 'react-responsive-modal';
import { AppData } from '../../../context';
import RenderSteps from './RenderSteps';



const LoginModal2 = () => {
  const { showLoginModal, setShowLoginModal, setClickedEnv } =
    useContext(AppData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSteps, setShowSteps] = useState(false);

  useEffect(() => {
    let needLogin = sessionStorage.getItem('isLogin') === 'true';
    if (showLoginModal || needLogin) {
      if (needLogin) {
        sessionStorage.setItem('isLogin', 'false');
      }
      setShowSteps(false);
      setIsModalOpen(true);
      setShowLoginModal(false);
    }
  }, [showLoginModal, setShowLoginModal]);

  const onCloseModal = () => {
    setClickedEnv('');
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        classNames={{
          modal: styles.custom_modal,
          overlay: styles.root,
        }}
      >
        <RenderSteps
          handleBack={() => setShowSteps(false)}
          handleClose={onCloseModal}
        />
      </Modal>
    </div>
  );
};

export default LoginModal2;
