import styles from "./VideoList.module.css";
import { useCallback, useContext, useRef, useState } from "react";
import { PauseIcon, PlayIcon } from "../../../svgIcons";
import { AppData } from "../../../context";
import useClickOutside from "../../../utils/hooks/useClickOutside";

const Videos = [
  {
    url: "https://s3.ap-south-1.amazonaws.com/technostrikers.com/introvideo.mp4",
    name: "Intro",
  },
];

const VideoList = ({ sendMessage }) => {
  const [selectedVideo, setSelectedVideo] = useState(false);
  const [filteredData, setFilteredData] = useState(Videos);
  const [pausedVideo, setPausedVideo] = useState();
  const ref = useRef(null);

  const { showVideoList, setShowVideoList } = useContext(AppData);

  const handlePlay = (url) => {
    if (url) {
      setSelectedVideo(url);
      sendMessage(
        "ReactUnityCommunicationManager",
        "GetReactData",
        JSON.stringify({
          event: "amitystartconfvideo",
          data: {
            videourl: url,
            status: "start",
          },
        })
      );
      setPausedVideo();
    }
  };

  const handlePause = (url) => {
    if (url) {
      setSelectedVideo();
      setPausedVideo(url);
      sendMessage(
        "ReactUnityCommunicationManager",
        "GetReactData",
        JSON.stringify({
          event: "amitystartconfvideo",
          data: { videourl: url, status: "pause" },
        })
      );
    }
  };

  const onClickOutside = useCallback(() => {
    setShowVideoList(false);
  }, [setShowVideoList]);

  useClickOutside(ref, onClickOutside);

  if (!showVideoList) {
    return null;
  }

  return (
    <div className={styles.userlist} ref={ref}>
      <main className={styles.leaderboard__profiles}>
        {filteredData.length === 0 && (
          <div className={styles.empty_records}>No videos found</div>
        )}
        {filteredData.map((item, index) => {
          return (
            <article key={index} className={styles.leaderboard__profile}>
              <span className={styles.leaderboard__name}>{item.name}</span>
              <span className={styles.button_wrap}>
                {/* {selectedVideo === item.url ? (
                  <button onClick={() => handlePause(item.url)}>
                    <PauseIcon />
                  </button>
                ) : ( */}
                <button onClick={() => handlePlay(item.url)}>
                  <PlayIcon />
                </button>
                {/* )} */}
              </span>
            </article>
          );
        })}
      </main>
    </div>
  );
};

export default VideoList;
