import { ToastContainer } from "react-toastify";
import AmityData from "./component/AmityData";
import AppContext from "./context";
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
import LoginModal2 from './component/CommonComponent/LoginModal2';
import UsernameModal from './component/CommonComponent/UsernameModal';
import './App.css'


function App() {
  const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

  return (

    <AppContext>
       <AgoraRTCProvider client={client}>
      <LoginModal2 />
      <AmityData />
      <ToastContainer />
      <UsernameModal />
      </AgoraRTCProvider>
    </AppContext>
  );
}

export default App;
