import styles from "./GalleryModal.module.css";
import { useContext, useEffect, useState } from "react";
import { AppData } from "../../../context";
import { getAllSelfies } from "../../../services";
import { SUCCESS_CODE } from "../../../constant";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
// import 'react-photo-view/dist/react-photo-view.css';
// import { PhotoProvider, PhotoView } from 'react-photo-view';

const GalleryModal = () => {
  const { showGalleryModal, setShowGalleryModal } = useContext(AppData);
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState([]);

  const fetchData = () => {
    getAllSelfies(
      (res) => {
        if (res.status === SUCCESS_CODE) {
          const data = res.data.map((item) => ({
            ...item,
            src: item.original,
            // thumbnail: 'data:image/jpeg;base64,' + item.original,
          }));
          console.warn("res.data", res.data, data);

          setImages(data);
          console.warn("selfi uploaded", res);
        }
      },
      (error) => {
        console.log("error in all selfi fetch ", error);
      }
    );
  };

  useEffect(() => {
    // getUserSelfies(
    //   res => {
    //     if (res.status === SUCCESS_CODE) {
    //       const data = res.data.map(item => ({
    //         ...item,
    //         original: item.gallery_image,
    //         thumbnail: item.gallery_image,
    //       }));
    //       setUserImages(data);
    //     }
    //   },
    //   error => {
    //     console.log('error in user selfi fetch', error);
    //   },
    // );

    fetchData();
  }, []);

  const onCloseClick = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showGalleryModal) {
      fetchData();
      setShowModal(true);
      setShowGalleryModal(false);
    }
  }, [showGalleryModal, setShowGalleryModal]);

  return (
    <div className={styles.modal_wrap}>
      <Lightbox
        open={showModal}
        close={onCloseClick}
        slides={images}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </div>
  );
};

export default GalleryModal;
