import React, { useState } from 'react';
import CustomModal from '../../CommonComponent/CustomModal';
import styles from './MediaUploadModal.module.css';
import CustomUploader from '../../CommonComponent/CustomUploader';

const MenuItems = ['RECENTS', 'UPLOAD'];
const MediaUploadModal = ({ setShowUploadModal }) => {
  const [currentSelected, setCurrentSelected] = useState('UPLOAD');

  const onCloseClick = () => {
    setShowUploadModal(prevState => !prevState);
  };

  return (
    <div className={styles.modal_wrap}>
      <CustomModal closeOutSideClick={true} onCloseClick={onCloseClick}>
        <div className={styles.modal_data}>
          <div className={styles.left_section}>
            <div className={styles.left_header}>Images</div>
            <div className={styles.menu_itmes}>
              {MenuItems.map(item => (
                <div
                  key={item}
                  className={
                    currentSelected === item ? styles.selected_item : ''
                  }
                  onClick={() => setCurrentSelected(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.right_section}>
            <CustomUploader />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default MediaUploadModal;
