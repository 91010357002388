import styles from "./UsersList.module.css";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { MicIcon, MuteMicIocn, SearchIcon } from "../../../svgIcons";
import { AppData } from "../../../context";
import useClickOutside from "../../../utils/hooks/useClickOutside";

const UsersList = ({ usersList, sendMessage, setKbdEventEnable }) => {
  const [filteredData, setFilteredData] = useState(usersList);
  const [searchValue, setSearchValue] = useState("");
  const [muteUsers, setMuteUsers] = useState([]);

  const ref = useRef(null);

  const { showUsersList, setShowUsersList } = useContext(AppData);

  const handleMicClick = (item) => {
    console.warn("test", {
      actor: item.actor,
      mute: item.mute === "true" ? "false" : "true",
    });
    if (item.mute !== "true") {
      setMuteUsers([...muteUsers, item.actor]);
    } else {
      setMuteUsers((prevState) =>
        prevState.filter((user) => user !== item.actor)
      );
    }

    sendMessage(
      "ReactUnityCommunicationManager",
      "GetReactData",
      JSON.stringify({
        event: "agoraMuteUnmute",
        data: {
          actor: item.actor,
          mute: item.mute === "true" ? "false" : "true",
        },
      })
    );
  };

  useEffect(() => {
    let data = usersList;
    if (searchValue && usersList.length > 0) {
      data = usersList.filter((element) => {
        return element.playername
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
    }
    const muted = usersList.reduce((acc, item) => {
      if (item.mute === "true") {
        acc.push(item.actor);
      }
      return acc
    }, []);
    setMuteUsers(muted);
    setFilteredData(data);
  }, [usersList]);

  const onClickOutside = useCallback(() => {
    setShowUsersList(false);
  }, [setShowUsersList]);

  useClickOutside(ref, onClickOutside);

  useEffect(() => {
    if (!showUsersList) {
      setKbdEventEnable(true);
    }
  }, [showUsersList, setKbdEventEnable]);

  if (!showUsersList) {
    return null;
  }

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const filteredData = usersList.filter((element) => {
      return element.playername.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredData(filteredData);
  };

  return (
    <div className={styles.userlist} ref={ref}>
      <main className={styles.leaderboard__profiles}>
        <div className={styles.Card}>
          <div className={styles.CardInner}>
            <div className={styles.container}>
              <div className={styles.InputContainer}>
                <input
                  className={styles.SearchBar}
                  placeholder="Search here"
                  onChange={handleSearch}
                  onClick={() => setKbdEventEnable(false)}
                  onBlur={() => setKbdEventEnable(true)}
                />
              </div>
              <div className={styles.Icon}>
                <SearchIcon />
              </div>
            </div>
          </div>
        </div>
        {filteredData.length === 0 && (
          <div className={styles.empty_records}>No users found</div>
        )}
        {filteredData.map((item, index) => {
          return (
            <article key={index} className={styles.leaderboard__profile}>
              <img
                src={"https://randomuser.me/api/portraits/men/32.jpg"}
                alt="Mark Zuckerberg"
                className={styles.leaderboard__picture}
                width={60}
                height={60}
              />
              <span className={styles.leaderboard__name}>
                {item.playername}
              </span>
              <span className={styles.button_wrap}>
                <button onClick={() => handleMicClick(item)}>
                  {muteUsers.includes(item.actor) ? (
                    <MuteMicIocn />
                  ) : (
                    <MicIcon />
                  )}
                </button>
              </span>
            </article>
          );
        })}
      </main>
    </div>
  );
};

export default UsersList;
