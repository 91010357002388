import { useCallback, useRef, useState } from 'react';
import { GestureIcon } from '../../../../svgIcons';
import InteractionMenu from '../../../CommonComponent/InteractionMenu';
import KBDButton from '../../../CommonComponent/KBDButton';
import Tooltip from '../../../CommonComponent/Tooltip';
import styles from './GestureMenu.module.css';
import useClickOutside from '../../../../utils/hooks/useClickOutside';

const emotes = [
  {
    url: 'https://dd2cgqlmnwvp5.cloudfront.net/emote_previews/PopAndLock.webm',
    name: "Pop 'n Lock",
    id: 3,
  },
  {
    url: 'https://dd2cgqlmnwvp5.cloudfront.net/emote_previews/GetDown.webm',
    name: 'Get Down',
    id: 4,
  },
  {
    url: 'https://dd2cgqlmnwvp5.cloudfront.net/emote_previews/Clap.webm',
    name: 'Clap',
    id: 5,
  },
];

const GestureMenu = ({ sendMessage }) => {
  const ref = useRef(null);
  const buttonRef = useRef(null);

  const [showEmotes, setShowEmotes] = useState(false);

  const onClickOutside = useCallback(() => {
    setShowEmotes(false);
  }, [setShowEmotes]);

  useClickOutside(ref, onClickOutside, buttonRef);

  const handleClick = id => {
    sendMessage(
      'ReactUnityCommunicationManager',
      'GetReactData',
      JSON.stringify({
        event: 'emotes',
        data: { id: id },
      }),
    );
  };
  return (
    <div className={styles.menu_wrap}>
      <div className={styles.button_group}>
        <div
          ref={buttonRef}
          className={styles.button_wrap}
          onClick={() => setShowEmotes(!showEmotes)}
        >
          <GestureIcon />
        </div>
      </div>
      {showEmotes && (
        <div ref={ref}>
          <InteractionMenu className={styles.interaction_wrap}>
            <div onClick={() => handleClick(0)}>❤️</div>

            <div onClick={() => handleClick(1)}>👍</div>

            <div onClick={() => handleClick(2)}>😂</div>

            <div onClick={() => handleClick(6)}>🙏</div>
            {emotes.map(emot => {
              return (
                <Tooltip key={emot.id} tooltipText={emot.name}>
                  <div onClick={() => handleClick(emot.id)}>
                    <video
                      loop
                      className={styles.video_wrap}
                      onMouseOver={event => event.target.play()}
                      onMouseOut={event => event.target.pause()}
                    >
                      <source src={emot.url} type="video/webm" />
                    </video>
                  </div>
                </Tooltip>
              );
            })}
          </InteractionMenu>
        </div>
      )}
    </div>
  );
};

export default GestureMenu;
