import React, { useCallback, useRef } from 'react';
import { CloseIcon } from '../../../svgIcons';
import useClickOutside from '../../../utils/hooks/useClickOutside';
import styles from './CustomModal.module.css';

const CustomModal = ({
  children,
  onCloseClick,
  hideCloseIcon,
  closeOutSideClick,
  contentClass = '',
  iconClass = '',
}) => {
  const ref = useRef(null);
  const closeButtonRef = useRef(null);

  const onClickOutside = useCallback(() => {
    if (closeOutSideClick) {
      onCloseClick();
    }
  }, [onCloseClick, closeOutSideClick]);

  useClickOutside(ref, onClickOutside, closeButtonRef);
  return (
    <div className={styles.modal_wrap}>
      <div className={`${styles.modal_content} ${contentClass}`} ref={ref}>
        {!hideCloseIcon && (
          <div
            className={`${styles.close_icon} ${iconClass}`}
            onClick={onCloseClick}
            ref={closeButtonRef}
          >
            <CloseIcon />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
