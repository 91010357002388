import styles from './Tooltip.module.css';
import * as RadixTooltip from '@radix-ui/react-tooltip';

const Tooltip = ({ children, tooltipText }) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content className={styles.tooltip_wrap} sideOffset={5}>
            {tooltipText}
            <RadixTooltip.Arrow className={styles.tooltip_arrow} />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default Tooltip;
