"use client";
import styles from "./amity.module.css";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  AgoraEvent,
  AgoraUserMute,
  AgoraUsers,
  EnvLoaded,
  OnFrameSelect,
  PlayerInfo,
  SUCCESS_CODE,
  SelfiUploaded,
  SetENV,
  ShowEnvLoader,
  ShowVideoPlayer,
} from "../constant";
import ActionButtonMenu from "../component/EnvPageComponent/ActionButtonMenu";
import MediaUploadModal from "../component/EnvPageComponent/MediaUploadModal";
import { AppData } from "../context";
import {
  fetchUserData,
  getAgoraToken,
  getEnvironment,
  getUserById,
  uploadSelfi,
} from "../services";
import { toast } from "react-toastify";
import AgoraRTC, {
  LocalUser,
  RemoteUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteUsers,
} from "agora-rtc-react";
import MediaDetails from "../component/EnvPageComponent/MediaDetails";
import CenterActionMenu from "../component/EnvPageComponent/CenterActionMenu";
import GestureMenu from "../component/EnvPageComponent/CenterActionMenu/GestureMenu";
import SelfiMenu from "../component/EnvPageComponent/SelfiMenu/index";
import SelfiViewer from "../component/EnvPageComponent/SelfiViewer/index";
import GalleryModal from "../component/EnvPageComponent/GalleryModal/index";
import AvatarMenu from "../component/EnvPageComponent/AvatarMenu/index";
import AvatarModel from "../component/EnvPageComponent/AvatarModel/index";
import UserProfileCard from "../component/EnvPageComponent/UserProfileCard/index";
import UsersList from "../component/EnvPageComponent/UsersList/index";
import Loader from "../component/CommonComponent/Loader";
import VideoList from "../component/EnvPageComponent/VideoList/index";
import VideoPlayer from "../component/EnvPageComponent/VideoPlayer/index";

function AmityData() {
  const { unityProvider, addEventListener, removeEventListener, sendMessage } =
    useUnityContext({
      loaderUrl: "/build/Ganesha.loader.js",
      dataUrl: "/build/Ganesha.data.unityweb",
      frameworkUrl: "/build/Ganesha.framework.js.unityweb",
      codeUrl: "/build/Ganesha.wasm.unityweb",
    });
  const [isApiCalling, setIsApiCalling] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [enablePointerEvents, setEnablePointerEvents] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isUnityEnv, setIsUnityEnv] = useState(false);
  const [envData, setEnvData] = useState(false);
  const [showMediaDetails, setShowMediaDetails] = useState(false);
  const [envLoaded, setEnvLoaded] = useState(false);
  const [isDataSent, setIsDataSent] = useState(false);
  const [showSelfi, setShowSelfi] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [videoUrl, setVideoUrl] = useState(
    "https://s3.amazonaws.com/webverseinfotech.com/AstraverseEnv/video/introvideo.mp4"
  );
  const [videoStatus, setVideoStatus] = useState("close");
  const slug = "product-quality-facilitator";
  const {
    uploadModalData,
    setUploadModalData,
    setKbdEventEnable,
    kbdEventEnable,
    userData,
    setUserData,
    accessToken,
    setShowLoginModal,
    setUserProfile,
    setIsUserJoined,
  } = useContext(AppData);

  const [calling, setCalling] = useState(false);
  const isConnected = useIsConnected();
  const [appId, setAppId] = useState("082116552dc34defb37c0f6fa2b10318");
  const [channel, setChannel] = useState("");
  const [token, setToken] = useState("");
  // const [screenTrack, setScreenTrack] = useState(null);
  console.log("userData._id", userData._id);
  useJoin(
    {
      appid: appId,
      channel: channel,
      token: token,
      uid: userData._id,
    },
    calling
  );
  // Local user
  const [micOn, setMic] = useState(true);
  const [mute, setMute] = useState(false);

  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  usePublish([localMicrophoneTrack]);
  const remoteUsers = useRemoteUsers();
  // console.log('remoteUsers', remoteUsers, remoteUsers.length);
  if (localMicrophoneTrack) {
    if (!mute) {
      localMicrophoneTrack.setMuted(true);
    } else {
      localMicrophoneTrack.setMuted(false);
    }
  }

  const agoraCall = (channelName) => {
    getAgoraToken(
      channelName,
      userData._id,
      (res) => {
        setToken(res.token);
      },
      (error) => {
        console.log("error in fetching environment ", error);
        toast.error(`We are facing error. please try after some time`, {
          position: "top-right",
          autoClose: 3000,
          theme: "dark",
        });
        setIsApiCalling(false);
      }
    );
  };

  // agoraCall("testtest")

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    if (token && userData.avatar_url) {
      setChannel("testtest");
      agoraCall("testtest");
    }
  }, [userData.avatar_url]);

  // stop loaders
  const handleLoader = () => {
    setLoadingComplete(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    setTimeout(() => {
      setEnablePointerEvents(true);
    }, 2000);
  };

  const fetchData = () => {
    const data = {
      modelurl:
        "https://s3.amazonaws.com/webverseinfotech.com/AstraverseEnv/ganesha",
      modelname: "ganesha",
      playername: "test",
    };

    getEnvironment(
      slug,
      (res) => {
        if (res.status === SUCCESS_CODE) {
          setEnvData({ ...res.data, ...data, placements: res.data.files });
          setIsApiCalling(false);
        }
      },
      (error) => {
        console.log("error in fetching environment ", error);
        toast.error(`We are facing error. please try after some time`, {
          position: "top-right",
          autoClose: 3000,
          theme: "dark",
        });
        setIsApiCalling(false);
      }
    );
  };

  const fetchUsersData = () => {
    fetchUserData(
      (res) => {
        setUserData(res?.user);
      },
      (err) => {
        console.log("Userdata fetching", err);
      }
    );
  };

  // get environment data from API
  useEffect(() => {
    fetchData();
  }, []);

  // send userdata for finalizations
  useEffect(() => {
    if (userData.avatar_url && envLoaded && !isDataSent) {
      sendMessage(
        "ReactUnityCommunicationManager",
        "GetReactData",
        JSON.stringify({
          event: "startphoton",
          data: {
            playername: userData.name,
            isadmin: userData.is_admin,
            avatarurl: userData.avatar_url,
            _id: userData._id,
          },
        })
      );
      setKbdEventEnable(true);
      // setIsLoading(false);
      setIsDataSent(true);
    }
  }, [envLoaded, userData.avatar_url, isDataSent, sendMessage]);

  // send environment data to Unity
  useEffect(() => {
    if (envData && isUnityEnv) {
      sendMessage(
        "ReactUnityCommunicationManager",
        "GetReactData",
        JSON.stringify({
          event: "setenv",
          data: envData,
        })
      );
      setKbdEventEnable(false);
      setIsUnityEnv(false);
    }
  }, [isUnityEnv, envData, sendMessage]);

  //set data when frame click
  const handleFrameSelect = useCallback(
    (data) => {
      if (data.data.mediaurl && envData.userId !== userData._id) {
        setShowMediaDetails(true);
      }
      setKbdEventEnable(false);
      const placementData =
        envData?.placements?.find(
          (item) => item.placementid === data.data.id
        ) || {};
      setUploadModalData({
        ...data.data,
        ...placementData,
        slug: slug,
        isadmin: envData.isadmin,
        envId: envData._id,
        fremeSelected: true,
        type: data.data.type,
        mediaUrl: data.data.mediaurl,
      });
    },
    [setKbdEventEnable, setUploadModalData, envData]
  );

  // unity function call handler
  const handleUnityData = useCallback(
    (data) => {
      if (data) {
        data = JSON.parse(data);
        if (data?.event === ShowEnvLoader && data.data.status === false) {
          handleLoader();
        }
        if (data?.event === OnFrameSelect && data.data.id) {
          handleFrameSelect(data);
        }
        if (data?.event === SetENV) {
          setIsUnityEnv(true);
          if (accessToken || sessionStorage.getItem("token")) {
            if (!userData.email) {
              fetchUsersData();
            }
          } else {
            setKbdEventEnable(false);
            setShowLoginModal(true);
          }
        }
        if (data?.event === EnvLoaded) {
          setEnvLoaded(true);
        }
        if (data?.event === SelfiUploaded) {
          setShowSelfi(data.data.imagebyte);
          handleSelfiUpload(data.data.imagebyte);
        }
        if (data?.event === PlayerInfo) {
          handleUserFetch(data.data._id);
        }
        if (data?.event === AgoraEvent) {
          setIsUserJoined(data.data.isjoin);
          setCalling(true);
        }
        if (data?.event === AgoraUsers) {
          if (data.data) {
            setUsersList([...data.data]);
          }
        }
        if (data?.event === AgoraUserMute) {
          setMute(data.data.mute !== "true");
        }
        if (data?.event === ShowVideoPlayer) {
          setVideoUrl(data.data.videourl);
          setVideoStatus(data.data.status);
        }
      }
    },
    [handleFrameSelect]
  );

  const handleUserFetch = (id) => {
    getUserById(
      id,
      (res) => {
        if (res.status === SUCCESS_CODE) {
          setUserProfile({ ...res.user });
        }
      },
      (error) => {}
    );
  };

  const handleSelfiUpload = (img) => {
    uploadSelfi(
      { original: img },
      (res) => {
        if (res.status === SUCCESS_CODE) {
          console.warn("selfi uploaded", res);
        }
      },
      (error) => {
        console.log("error in selfi upload", error);
      }
    );
  };

  // send data to unity when change media
  useEffect(() => {
    if (uploadModalData.mediaChanged) {
      setShowUploadModal(false);
      setUploadModalData({
        ...uploadModalData,
        fremeSelected: false,
        mediaChanged: false,
        mediaUploaded: false,
      });
      sendMessage(
        "ReactUnityCommunicationManager",
        "GetReactData",
        JSON.stringify({
          event: "onmediaupload",
          data: {
            mediaurl: uploadModalData.mediaUrl,
            type: uploadModalData.type,
          },
        })
      );
      setKbdEventEnable(true);
    }
  }, [uploadModalData, setUploadModalData, sendMessage, setKbdEventEnable]);

  // addEventListener added for unity functions
  useEffect(() => {
    addEventListener("GetUnityData", handleUnityData);
    sessionStorage.setItem("envOpen", "true");
    return async () => {
      removeEventListener("GetUnityData", handleUnityData);
    };
  }, [addEventListener, handleUnityData]);

  const envVisibility = useMemo(() => {
    return `${isLoading ? 0 : 1}`;
  }, [isLoading]);

  const envVisibility1 = useMemo(() => {
    return `${isLoading ? 1 : 0}`;
  }, [isLoading]);

  const displayEnv = useMemo(() => {
    return `${enablePointerEvents ? "none" : ""}`;
  }, [enablePointerEvents]);

  const handleHideUploadModal = (value) => {
    setShowUploadModal(value);
    setKbdEventEnable(true);
  };

  const handleHideDetailModal = () => {
    setShowMediaDetails(false);
    setKbdEventEnable(true);
  };

  useEffect(() => {
    if (sendMessage) {
      sendMessage(
        "ReactUnityCommunicationManager",
        "GetReactData",
        JSON.stringify({
          event: "keyboardmanage",
          data: { status: kbdEventEnable },
        })
      );
    }
  }, [kbdEventEnable, sendMessage]);

  const handleHideSelfiViewer = () => {
    setShowSelfi(false);
    setKbdEventEnable(true);
  };

  return (
    <>
      <AvatarModel />

      <div className={styles.main}>
        {isLoading &&
          (!userData.avatar_url ||
          (userData.avatar_url && envLoaded && !isDataSent) ? (
            <div>
              <div className={styles.loader_wrap}>
                <video className={styles.video_wrap} autoPlay muted loop>
                  <source
                    src={
                      "https://cdn.pixabay.com/video/2024/03/23/205303-926737113_large.mp4"
                    }
                    type="video/webm"
                  />
                </video>
              </div>
            </div>
          ) : (
            <Loader />
          ))}
        <div
          className={styles.env_content}
          style={{ opacity: envVisibility1, display: displayEnv }}
        ></div>
        <div
          style={{
            opacity: envVisibility,
            pointerEvents: enablePointerEvents ? "auto" : "none",
            height: "100vh",
          }}
        >
          <Unity
            unityProvider={unityProvider}
            style={{ width: "100%", height: "calc(100vh - 0px)" }}
          />
          {!isLoading && (
            <>
              <ActionButtonMenu envData={envData} />
              <CenterActionMenu sendMessage={sendMessage} />
              <GalleryModal sendMessage={sendMessage} />
              <AvatarMenu sendMessage={sendMessage} />
              <SelfiMenu sendMessage={sendMessage} />
              <GestureMenu sendMessage={sendMessage} />
              <UserProfileCard />
              <UsersList
                usersList={usersList}
                sendMessage={sendMessage}
                setKbdEventEnable={setKbdEventEnable}
              />
              <VideoList sendMessage={sendMessage} />
              {videoStatus !== "close" && (
                <VideoPlayer
                  videoUrl={videoUrl}
                  videoStatus={videoStatus}
                  sendMessage={sendMessage}
                />
              )}
              {console.log("isConnected", isConnected)}
              {isConnected && (
                <>
                  <LocalUser
                    micOn={micOn}
                    // audioTrack={localMicrophoneTrack}
                    cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                  >
                    <samp className="user-name">You</samp>
                  </LocalUser>
                  {remoteUsers.map((user) => (
                    <RemoteUser
                      key={user.uid}
                      cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                      user={user}
                    >
                      <samp>{user.uid}</samp>
                    </RemoteUser>
                  ))}
                </>
              )}
              {showUploadModal && (
                <MediaUploadModal setShowUploadModal={handleHideUploadModal} />
              )}
              {showMediaDetails && (
                <MediaDetails onCloseClick={handleHideDetailModal} />
              )}
              {showSelfi && (
                <SelfiViewer
                  imgUrl={showSelfi}
                  onCloseClick={handleHideSelfiViewer}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default AmityData;
