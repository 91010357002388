import Astraverse_logo from "../assets/Astraverse_logo.png";
import Amity_logo from "../assets/amity_logo.png";

export const AppLogo = () => {
  return <img src={Astraverse_logo} alt="atsrverse" width={50} height={50} />;
};

export const GoogleIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M13.3256 24.0002C13.3256 22.8606 13.4884 21.7211 13.8953 20.663L7.87209 16.0234C6.65116 18.4653 6 21.1513 6 24.0002C6 26.849 6.65116 29.5351 7.87209 31.8955L13.8953 27.256C13.4884 26.2793 13.3256 25.1397 13.3256 24.0002Z"
          fill="#FBBC05"
        ></path>
        <path
          d="M7.87207 16.1044L13.8953 20.7439C15.279 16.43 19.186 13.4184 23.907 13.4184C26.4302 13.4184 28.7093 14.3137 30.5 15.7788L35.7093 10.5695C32.5349 7.80208 28.4651 6.09277 23.907 6.09277C16.8256 6.09277 10.7209 10.1625 7.87207 16.1044Z"
          fill="#EA4335"
        ></path>
        <path
          d="M23.907 20.7441V27.6627H33.5116C32.6163 32.0581 28.8721 34.5813 23.907 34.5813C19.186 34.5813 15.279 31.5697 13.8953 27.3372L7.87207 31.9767C10.7209 37.8372 16.8256 41.9069 23.907 41.9069C32.8604 41.9069 41 35.3953 41 24C41 22.9418 40.8372 21.8023 40.593 20.7441H23.907Z"
          fill="#34A853"
        ></path>
        <path
          d="M23.9067 20.7441V27.6627H33.5114C33.023 30.0232 31.7207 31.8139 29.8486 33.0348L35.5463 37.4302C38.8021 34.3372 40.9998 29.8604 40.9998 24C40.9998 22.9418 40.837 21.8023 40.5928 20.7441H23.9067Z"
          fill="#4285F4"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="35"
            height="35.814"
            fill="white"
            transform="translate(6 6.09277)"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export const AppleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        fill="currentColor"
        d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z"
      ></path>
    </svg>
  );
};

export const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        fill="currentColor"
        d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
      ></path>
    </svg>
  );
};

export const MicrosoftIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 2499.6 2500"
      viewBox="0 0 2499.6 2500"
      width="32"
      height="32"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-sm absolute left-4 absolute-center-y"
    >
      <path d="m1187.9 1187.9h-1187.9v-1187.9h1187.9z" fill="#f1511b"></path>
      <path
        d="m2499.6 1187.9h-1188v-1187.9h1187.9v1187.9z"
        fill="#80cc28"
      ></path>
      <path d="m1187.9 2500h-1187.9v-1187.9h1187.9z" fill="#00adef"></path>
      <path d="m2499.6 2500h-1188v-1187.9h1187.9v1187.9z" fill="#fbbc09"></path>
    </svg>
  );
};

export const HideChatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        fill="currentColor"
        d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"
      ></path>
    </svg>
  );
};

export const ShowChatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        fill="currentColor"
        d="M15 4v7H5.17L4 12.17V4h11m1-2H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm5 4h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1z"
      ></path>
    </svg>
  );
};

export const MenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        fill="#fff"
        d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
      ></path>
    </svg>
  );
};

export const LikeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        fill="currentColor"
        d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
      ></path>
    </svg>
  );
};

export const ShareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="currentColor"
    >
      <g>
        <rect fill="none" height="24" width="24"></rect>
      </g>
      <g>
        <path d="M16,5l-1.42,1.42l-1.59-1.59V16h-1.98V4.83L9.42,6.42L8,5l4-4L16,5z M20,10v11c0,1.1-0.9,2-2,2H6c-1.11,0-2-0.9-2-2V10 c0-1.11,0.89-2,2-2h3v2H6v11h12V10h-3V8h3C19.1,8,20,8.89,20,10z"></path>
      </g>
    </svg>
  );
};

export const EyeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        fill="currentColor"
        d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
      ></path>
    </svg>
  );
};

export const EmojiIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        fill="currentColor"
        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
      ></path>
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        fill="currentColor"
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      ></path>
    </svg>
  );
};

export const BackArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        fill="currentColor"
        d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"
      ></path>
    </svg>
  );
};

export const RandomizeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path
        fill="currentColor"
        d="M481-158q-131 0-225.5-94.5T161-478v-45l-80 80-39-39 149-149 149 149-39 39-80-80v45q0 107 76.5 183.5T481-218q29 0 55-5t49-15l43 43q-36 20-72.5 28.5T481-158Zm289-169L621-476l40-40 79 79v-41q0-107-76.5-183.5T480-738q-29 0-55 5.5T376-719l-43-43q36-20 72.5-28t74.5-8q131 0 225.5 94.5T800-478v43l80-80 39 39-149 149Z"
      ></path>
    </svg>
  );
};

export const FetchingIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      className={className}
      width="24"
    >
      <path
        fill="currentColor"
        d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z"
      ></path>
    </svg>
  );
};

export const AvatarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        fill="#000000"
        d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
      ></path>
    </svg>
  );
};

export const GestureIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9531 5.98438C12.4219 5.98438 11.9531 5.78125 11.5469 5.375C11.1406 4.96875 10.9375 4.5 10.9375 3.96875C10.9375 3.4375 11.1406 2.98438 11.5469 2.60938C11.9531 2.20312 12.4219 2 12.9531 2C13.4844 2 13.9531 2.20312 14.3594 2.60938C14.7656 2.98438 14.9688 3.4375 14.9688 3.96875C14.9688 4.5 14.7656 4.96875 14.3594 5.375C13.9531 5.78125 13.4844 5.98438 12.9531 5.98438Z"
        fill="currentColor"
      ></path>
      <path
        d="M7.125 6.21875C6.4375 5.125 6.0625 3.84375 6 2.375V2H7.96875V2.46875C7.96875 3.9375 8.48438 5.09375 9.51562 5.9375C10.4219 6.625 10.5781 6.96875 11.9844 6.96875C13.8281 6.96875 15.4531 7.54688 16.8594 8.70312C18.2656 9.92188 18.9688 11.5156 18.9688 13.4844V14H17V13.4844C17 12.1719 16.5312 11.1406 15.5938 10.3906C15.4688 10.2656 15.2656 10.1562 14.9844 10.0625V14.9844L9.98438 15.9688V8.375C8.76562 8 7.8125 7.28125 7.125 6.21875Z"
        fill="currentColor"
      ></path>
      <path
        d="M10 20L9.29817 17.8945C9.13074 17.3922 9.38386 16.8465 9.87547 16.6498L14 15L17 22"
        stroke="currentColor"
        strokeWidth="2.2"
        strokeLinecap="square"
      ></path>
      <path
        d="M20 7L20.9375 4.9375L23 4L20.9375 3.0625L20 1L19.0625 3.0625L17 4L19.0625 4.9375L20 7Z"
        fill="currentColor"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14696 6.1543L5.14958 7.8909C3.85044 8.79392 3 10.2973 3 11.9994C3 14.0498 4.23421 15.8119 6.00025 16.5834L6.0003 18.7096C3.10866 17.8491 1 15.1705 1 11.9994C1 9.55696 2.25088 7.4067 4.14696 6.1543Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const SelfiIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#ffffff"
        stroke="none"
      >
        <path
          d="M2060 4529 c-44 -27 -38 -19 -198 -286 l-133 -223 -224 0 -225 0 0
73 c0 89 -21 129 -83 157 -38 18 -68 20 -337 20 -287 0 -297 -1 -340 -22 -58
-31 -80 -73 -80 -159 l0 -66 -74 -6 c-102 -9 -175 -39 -239 -97 -64 -58 -101
-124 -117 -205 -8 -43 -10 -445 -8 -1460 l3 -1400 31 -65 c39 -83 101 -145
184 -184 l65 -31 2275 0 2275 0 65 31 c83 39 145 101 184 184 l31 65 3 1400
c2 1015 0 1417 -8 1460 -7 33 -24 82 -38 108 -35 66 -120 141 -190 169 l-57
23 -717 3 -718 3 -126 212 c-162 272 -162 272 -205 296 -36 20 -51 21 -500 21
-456 0 -465 0 -499 -21z m2525 -1002 c46 -29 68 -65 73 -121 3 -41 -1 -58 -20
-86 -45 -67 -61 -70 -356 -70 -291 0 -312 4 -354 62 -60 85 -12 202 93 228 19
5 147 8 284 7 223 -2 253 -4 280 -20z m-1895 -182 c361 -34 709 -298 852 -647
58 -142 72 -226 72 -408 0 -147 -3 -176 -27 -264 -97 -360 -377 -648 -731
-750 -571 -166 -1167 172 -1323 750 -24 88 -27 117 -27 264 0 182 14 266 72
408 148 360 497 618 886 652 80 7 97 7 226 -5z"
        />
        <path
          d="M2478 3029 c-362 -41 -635 -331 -655 -694 -16 -299 146 -576 412
-705 118 -57 190 -73 325 -73 135 0 207 16 325 73 368 178 520 632 335 1000
-136 270 -440 434 -742 399z"
        />
      </g>
    </svg>
  );
};

export const GalleryIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#ffffff"
        stroke="none"
      >
        <path
          d="M1475 5100 c-112 -38 -207 -130 -241 -236 -9 -27 -53 -246 -98 -485
l-81 -434 -375 -5 -375 -5 -67 -33 c-107 -52 -183 -152 -208 -270 -7 -31 -10
-614 -8 -1692 l3 -1645 32 -67 c40 -85 106 -151 191 -191 l67 -32 1675 0 1675
0 58 23 c81 33 166 114 203 194 28 62 29 67 32 256 l3 192 42 -7 c23 -5 85 -8
137 -8 84 0 102 4 153 29 73 35 148 109 180 178 38 81 632 3250 623 3325 -10
85 -43 158 -98 219 -98 107 15 80 -1741 410 -864 162 -1606 297 -1647 300 -58
3 -89 0 -135 -16z m1710 -525 c781 -146 1439 -272 1463 -280 89 -29 160 -116
177 -216 5 -26 -57 -377 -220 -1250 -125 -668 -230 -1217 -234 -1221 -3 -4
-96 117 -206 267 l-200 274 -5 758 -5 758 -28 58 c-37 74 -110 147 -184 184
l-58 28 -1187 3 c-654 1 -1188 6 -1188 11 0 37 134 710 146 735 50 99 179 173
279 160 17 -2 669 -123 1450 -269z m371 -904 c54 -25 111 -83 138 -140 l21
-46 3 -1248 c1 -796 -1 -1248 -7 -1245 -8 2 -793 735 -1128 1051 l-72 69 -215
-131 c-117 -72 -218 -131 -222 -131 -5 0 -158 149 -339 330 -181 182 -333 330
-338 330 -4 0 -257 -164 -563 -365 -305 -201 -557 -365 -560 -365 -2 0 -3 384
-2 853 l3 852 27 50 c24 45 52 78 100 114 54 41 28 40 1603 40 1452 1 1512 0
1551 -18z"
        />
        <path
          d="M2830 3410 c-331 -62 -486 -429 -300 -708 176 -263 574 -264 749 -2
198 297 13 685 -340 713 -35 3 -84 2 -109 -3z"
        />
      </g>
    </svg>
  );
};

export const InfoIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#ffffff"
        stroke="none"
      >
        <path
          d="M2321 5110 c-766 -75 -1462 -491 -1893 -1135 -156 -233 -302 -572
-362 -840 -49 -219 -60 -324 -60 -575 0 -250 11 -355 60 -575 73 -328 242
-689 452 -967 409 -541 1000 -888 1692 -995 161 -24 539 -24 700 1 452 69 840
231 1197 497 126 94 398 366 492 492 266 357 428 745 497 1197 25 161 25 539
1 700 -82 530 -304 1001 -646 1373 -406 440 -919 715 -1508 807 -148 24 -475
34 -622 20z m641 -1017 c185 -88 264 -290 183 -471 -30 -65 -113 -148 -180
-178 -195 -89 -440 -11 -533 171 -37 73 -43 197 -11 280 28 75 114 165 192
201 108 49 241 48 349 -3z m-387 -1037 c234 -50 359 -219 340 -460 -11 -135
-23 -189 -115 -518 -118 -416 -133 -483 -134 -598 -2 -145 29 -183 163 -200
72 -9 166 2 249 31 30 10 56 19 57 19 10 0 -44 -174 -56 -180 -37 -21 -340
-129 -394 -141 -76 -16 -262 -16 -340 1 -92 19 -164 58 -231 125 -87 86 -108
144 -108 295 1 143 13 205 120 583 110 391 127 463 128 572 1 104 -16 145 -74
180 -30 17 -51 20 -140 19 -87 0 -118 -5 -178 -27 l-72 -26 5 22 c3 12 13 53
23 91 l17 68 55 22 c110 45 303 108 368 121 87 17 241 18 317 1z"
        />
      </g>
    </svg>
  );
};

export const MicIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M2435 5114 c-279 -44 -517 -183 -677 -394 -68 -90 -143 -246 -175
-365 l-27 -100 -4 -880 c-2 -586 0 -915 7 -986 15 -139 41 -233 101 -354 184
-374 591 -600 999 -555 135 15 227 41 346 100 251 123 453 366 525 629 38 142
42 247 38 1166 l-4 880 -27 100 c-99 370 -377 646 -742 736 -79 20 -294 33
-360 23z"
        />
        <path
          d="M1021 2632 c-19 -9 -48 -34 -64 -54 -38 -47 -44 -104 -28 -260 43
-415 238 -800 543 -1068 236 -209 527 -347 828 -394 l75 -11 3 -243 2 -242
-347 0 c-317 0 -352 -2 -389 -19 -134 -61 -135 -255 -2 -320 44 -21 48 -21
918 -21 870 0 874 0 918 21 133 65 132 259 -2 320 -37 17 -72 19 -389 19
l-347 0 2 242 3 243 75 11 c729 113 1294 715 1371 1462 16 156 10 213 -28 260
-75 94 -202 98 -280 9 -35 -40 -37 -48 -53 -222 -28 -304 -147 -566 -352 -777
-328 -337 -790 -465 -1248 -348 -421 108 -774 453 -895 874 -29 103 -42 177
-50 301 -4 50 -11 104 -16 121 -11 36 -63 89 -102 103 -42 16 -108 13 -146 -7z"
        />
      </g>
    </svg>
  );
};

export const MuteMicIocn = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M2425 4629 c-170 -33 -314 -109 -430 -224 -135 -136 -225 -332 -229
-502 l-1 -61 719 -722 c713 -714 720 -721 753 -715 45 8 100 59 113 103 8 25
10 263 8 752 -3 629 -6 722 -20 770 -32 108 -60 170 -108 243 -112 173 -271
287 -480 344 -68 18 -258 25 -325 12z"
        />
        <path
          d="M893 4300 c-46 -20 -79 -64 -89 -117 -14 -74 -8 -82 487 -578 l468
-470 4 -355 c3 -375 4 -381 57 -522 69 -186 257 -370 451 -442 229 -85 475
-69 677 45 l55 31 114 -114 115 -115 -64 -41 c-432 -281 -991 -230 -1381 127
-153 141 -289 390 -326 596 -6 34 -15 109 -20 169 -8 114 -19 138 -75 180 -39
29 -133 29 -172 0 -62 -46 -69 -65 -68 -192 0 -66 8 -154 18 -202 121 -607
582 -1058 1189 -1161 l67 -11 0 -164 0 -163 -269 -3 c-254 -3 -271 -4 -297
-24 -53 -39 -69 -71 -69 -134 0 -63 16 -95 69 -134 27 -21 36 -21 726 -21 690
0 699 0 726 21 53 39 69 71 69 134 0 63 -16 95 -69 134 -26 20 -43 21 -297 24
l-269 3 0 163 0 164 98 17 c221 40 437 136 629 280 10 7 89 -67 320 -297 261
-260 314 -308 351 -319 116 -34 227 77 193 193 -11 38 -202 232 -1629 1662
-1124 1125 -1629 1624 -1657 1637 -51 24 -79 23 -132 -1z"
        />
        <path
          d="M3775 2706 c-63 -29 -95 -85 -95 -166 0 -117 -35 -285 -85 -408 -47
-116 -25 -195 67 -240 85 -42 164 -10 212 87 68 135 117 351 120 523 1 126 -6
146 -66 191 -34 25 -113 32 -153 13z"
        />
      </g>
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#657789"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-search"
    >
      <circle cx="11" cy="11" r="8" />
      <line x1="21" y1="21" x2="16.65" y2="16.65" />
    </svg>
  );
};

export const UserIcon = ({ color }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="18.000000pt"
      height="18.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={color ? color : "#fff"}
        stroke="none"
      >
        <path
          d="M2270 4835 c-769 -103 -1429 -575 -1765 -1260 -107 -220 -171 -417
-212 -663 -26 -152 -26 -552 0 -704 83 -495 303 -926 647 -1268 327 -326 729
-541 1175 -629 333 -65 706 -53 1039 34 524 137 1004 481 1312 942 428 640
505 1467 203 2170 -308 720 -948 1227 -1719 1363 -161 29 -520 36 -680 15z
m459 -1071 c130 -30 275 -121 363 -229 228 -279 208 -675 -47 -931 -67 -68
-98 -90 -176 -127 -286 -139 -596 -84 -812 143 -215 225 -253 560 -95 828 42
73 167 198 240 240 157 93 340 119 527 76z m48 -1699 c322 -43 614 -168 865
-372 90 -73 125 -114 138 -163 17 -65 0 -107 -72 -177 -210 -200 -492 -343
-819 -415 -91 -19 -133 -22 -329 -22 -252 0 -336 12 -539 80 -240 80 -488 228
-627 376 -118 125 -70 222 201 407 350 239 767 340 1182 286z"
        />
      </g>
    </svg>
  );
};

export const AmityLogo = () => {
  return <img src={Amity_logo} alt="atsrverse" width={50} height={50} />;
};

export const VideoIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="18.000000pt"
      height="18.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M1550 4562 c0 -4 201 -208 448 -455 l447 -447 340 0 340 0 -455 455
-455 455 -332 0 c-183 0 -333 -3 -333 -8z"
        />
        <path
          d="M3186 4115 l457 -455 454 0 c427 0 453 1 453 18 0 38 -44 172 -84
258 -58 121 -114 203 -205 297 -172 178 -364 279 -608 322 -67 12 -178 15
-505 15 l-419 0 457 -455z"
        />
        <path
          d="M1085 4414 c-88 -52 -152 -104 -231 -186 -87 -90 -143 -173 -200
-293 -43 -90 -94 -258 -83 -269 3 -4 310 -5 682 -4 l677 3 -393 393 -393 392
-59 -36z"
        />
        <path
          d="M550 2421 c0 -928 1 -944 51 -1106 25 -81 84 -203 137 -284 49 -74
171 -202 252 -263 89 -68 264 -152 385 -185 l100 -28 1035 -3 c733 -2 1058 0
1114 8 163 24 325 85 461 175 78 52 204 172 267 255 68 89 152 264 185 385
l28 100 3 908 3 907 -2011 0 -2010 0 0 -869z m2194 183 c535 -294 533 -293
544 -389 6 -59 -20 -128 -61 -159 -18 -13 -243 -140 -501 -281 -517 -285 -521
-286 -609 -247 -31 14 -55 34 -74 63 l-28 43 0 561 0 561 27 41 c36 55 98 85
163 80 43 -3 116 -40 539 -273z"
        />
      </g>
    </svg>
  );
};

export const PlayIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="18.000000pt"
      height="18.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M425 5105 c-60 -22 -110 -68 -139 -127 l-26 -52 0 -2366 0 -2366 26
-52 c31 -63 81 -106 148 -128 72 -24 141 -14 229 34 40 21 974 546 2077 1166
1250 703 2019 1142 2043 1164 54 53 77 106 77 182 0 76 -23 129 -77 182 -24
22 -793 461 -2043 1164 -1103 620 -2038 1145 -2078 1167 -86 46 -168 57 -237
32z"
        />
      </g>
    </svg>
  );
};

export const PauseIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="18.000000pt"
      height="18.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M1321 4784 c-169 -45 -301 -180 -346 -351 -23 -86 -23 -3660 0 -3746
45 -173 178 -307 350 -352 80 -20 310 -20 390 0 172 45 305 179 350 352 23 86
23 3660 0 3746 -45 173 -178 307 -350 352 -78 20 -318 20 -394 -1z"
        />
        <path
          d="M3401 4784 c-169 -45 -301 -180 -346 -351 -23 -86 -23 -3660 0 -3746
45 -173 178 -307 350 -352 80 -20 310 -20 390 0 172 45 305 179 350 352 23 86
23 3660 0 3746 -45 173 -178 307 -350 352 -78 20 -318 20 -394 -1z"
        />
      </g>
    </svg>
  );
};

export const ExitIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="18.000000pt"
      height="18.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M142 5101 c-66 -23 -89 -43 -118 -106 l-24 -50 0 -2165 c0 -1480 3
-2177 11 -2203 13 -48 63 -105 112 -127 21 -10 530 -116 1132 -236 1057 -211
1095 -218 1142 -206 59 16 110 56 137 108 18 33 22 61 24 176 l4 138 559 0
c531 0 562 1 597 19 20 11 49 32 64 48 60 63 58 40 58 783 0 593 -2 685 -16
718 -33 79 -110 132 -194 132 -88 0 -152 -38 -193 -115 -22 -39 -22 -49 -25
-603 l-3 -562 -424 0 -425 0 0 1721 c0 1261 -3 1732 -11 1760 -15 49 -59 102
-102 123 -19 8 -194 64 -388 122 -195 58 -356 108 -358 110 -2 2 381 4 852 4
l857 0 0 -536 c0 -377 3 -549 12 -582 16 -66 87 -135 154 -151 99 -24 194 21
238 112 l27 53 -3 695 -3 695 -28 42 c-15 23 -46 54 -69 69 l-42 28 -1750 2
c-1671 2 -1753 2 -1804 -16z"
        />
        <path
          d="M4206 3618 c-58 -16 -129 -91 -145 -151 -13 -52 -7 -104 17 -152 8
-16 80 -96 160 -177 l146 -147 -620 -3 c-612 -3 -619 -3 -659 -25 -76 -41
-115 -105 -115 -190 1 -91 65 -180 147 -202 25 -7 253 -11 647 -11 l610 0
-161 -162 c-147 -149 -161 -167 -172 -213 -24 -101 20 -195 114 -240 47 -24
64 -27 111 -22 30 3 69 15 87 26 17 11 188 177 378 368 366 369 369 373 369
464 -1 77 -29 112 -373 457 -183 183 -350 344 -372 357 -48 28 -116 38 -169
23z"
        />
      </g>
    </svg>
  );
};
