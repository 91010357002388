import CustomModal from '../../CommonComponent/CustomModal';
import styles from './MediaDetails.module.css';
import { useContext } from 'react';
import { AppData } from '../../../context';
const dummyDetails = {
  title: 'Test Test Test',
  description:
    'A dummy is a type of doll that looks like a person. Entertainers called ventriloquists can make dummies appear to talk. The automobile industry uses dummies in cars to study how safe cars are during a crash. A dummy can also be anything that looks real but doesn’t work: a fake. Actors in a play might use certain props that are dummies, such as a dummy laptop. Dummy is also an insult used to mean “an ignorant person.”',
  link: 'https://google.com',
};
const MediaDetails = ({ onCloseClick }) => {
  const { uploadModalData } = useContext(AppData);
  const details = uploadModalData.details || dummyDetails;
  const { title, description, link } = details || dummyDetails;

  const handleLinkClick = () => {
    window.open(link, '_blank');
  };

  return (
    <div className={styles.modal_wrap}>
      <CustomModal
        closeOutSideClick={true}
        contentClass={styles.content_wrap}
        iconClass={styles.close_icon}
        onCloseClick={onCloseClick}
      >
        <div className={styles.media_wrap}>
          <div className={styles.left_section}>
            {uploadModalData.type === 'image' ? (
              <img
                width={1000}
                height={window.innerHeight - 100}
                src={uploadModalData.mediaurl}
                alt="User"
              />
            ) : (
              <video height={window.innerHeight - 100} autoPlay muted loop>
                <source src={uploadModalData.mediaurl} type="video/webm" />
              </video>
            )}
          </div>
          {details && (title || description || link) && (
            <div className={styles.details}>
              {title && <div className={styles.title}>{title}</div>}
              {description && (
                <div className={styles.description}>{description}</div>
              )}
              {link && (
                <div className={styles.link} onClick={handleLinkClick}>
                  Visit
                </div>
              )}
            </div>
          )}
        </div>
      </CustomModal>
    </div>
  );
};

export default MediaDetails;
