import { useContext, useRef, useState } from 'react';
import styles from './RenderSteps.module.css';
import {  signIn, signUp } from '../../../../services/index';
import { SUCCESS_CODE } from '../../../../constant';
import { AppData } from '../../../../context';
import { toast } from 'react-toastify';

const RenderSteps = ({ handleClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isUserSignUp, setIsUserSignUp] = useState(false);
  const [data, setData] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const { setAccessToken } = useContext(AppData);
  const emailInput = useRef();
  const nameInput = useRef();
  const [errors, setErrors] = useState('');

  const handleLogin = async e => {
    setLoading(true);
    await signIn(
      { email: data.email.toLocaleLowerCase() },
      res => {
        if (res?.status === SUCCESS_CODE) {
          setAccessToken(res.token);
          sessionStorage.setItem('token', res.token);
          handleClose();
        } else {
          toast.error(res.message, {
            position: 'top-right',
            autoClose: 3000,
            theme: 'dark',
          });
        }

        setLoading(false);
      },
      err => {
        setLoading(false);
        let msg = err.response.data.message;
        toast.error(msg, {
          position: 'top-right',
          autoClose: 3000,
          theme: 'dark',
        });
      },
    );
  };

  const handleSignUp = async e => {
    setLoading(true);
    await signUp(
      { ...data, email: data.email.toLocaleLowerCase() },
      res => {
        if (res?.status === SUCCESS_CODE) {
          setAccessToken(res.token);
          sessionStorage.setItem('token', res.token);
          handleClose();
        } else {
          toast.error(res.message, {
            position: 'top-right',
            autoClose: 3000,
            theme: 'dark',
          });
        }

        setLoading(false);
      },
      err => {
        setLoading(false);
        console.log('Error in signin', err);
        let msg = err.response.data.message;
        toast.error(msg, {
          position: 'top-right',
          autoClose: 3000,
          theme: 'dark',
        });
      },
    );
  };

  const handleDataChange = (label, e) => {
    setData({ ...data, [label]: e.target.value });
  };

  const handleEmailClick = () => {
    emailInput.current?.focus();
  };

  const handleNameClick = () => {
    nameInput.current?.focus();
  };

  const handleLinkClick = tab => {
    setCurrentStep(tab);
    setErrors('');
    setData('');
  };

  return (
    <div className={styles.steps_content}>
      {currentStep === 0 && (
        <div>
          <div className={styles.title}>Login</div>
          <div>
            <form
              onSubmit={e => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <div className={`${styles.form__group}  ${styles.field}`}>
                <input
                  type="email"
                  className={styles.form__field}
                  placeholder="Email"
                  ref={emailInput}
                  name="email"
                  value={data.email}
                  id="email"
                  onChange={e => handleDataChange('email', e)}
                  required
                />
                <label
                  for="name"
                  onClick={handleEmailClick}
                  className={styles.form__label}
                >
                  Email
                </label>
                {errors && <div className={styles.error}>{errors}</div>}
              </div>

              <button
                className={styles.submit_button}
                disabled={loading}
                type="submit"
              >
                Login
              </button>
              <div>
                Don&apos;t have an account?{' '}
                <span
                  className={styles.link_text}
                  onClick={() => handleLinkClick(1)}
                >
                  Sign Up
                </span>{' '}
              </div>
            </form>
          </div>
        </div>
      )}

      {currentStep === 1 && (
        <div>
          <div className={styles.title}>Sign Up</div>
          <div>
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSignUp();
              }}
            >
              <div className={`${styles.form__group}  ${styles.field}`}>
                <input
                  type="text"
                  className={styles.form__field}
                  placeholder="name"
                  ref={nameInput}
                  name="name"
                  id="name"
                  onChange={e => handleDataChange('name', e)}
                  required
                />
                <label
                  for="name"
                  onClick={handleNameClick}
                  className={styles.form__label}
                >
                  Name
                </label>
              </div>
              <div
                className={`${styles.form__group}  ${styles.field} ${styles.extra_space}`}
              >
                <input
                  type="email"
                  className={styles.form__field}
                  placeholder="Email"
                  ref={emailInput}
                  name="email"
                  id="email"
                  onChange={e => handleDataChange('email', e)}
                  required
                />
                <label
                  for="name"
                  onClick={handleEmailClick}
                  className={styles.form__label}
                >
                  Email
                </label>
              </div>
              {errors && <div className={styles.error}>{errors}</div>}

              <button
                className={styles.submit_button}
                disabled={loading}
                type="submit"
              >
                Sign Up
              </button>
              <div>
                Already have an account?{' '}
                <span
                  className={styles.link_text}
                  onClick={() => handleLinkClick(0)}
                >
                  Login{' '}
                </span>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default RenderSteps;
