// Unity events
export const ShowEnvLoader = 'showenvloader';
export const OnFrameSelect = 'onframeselect';
export const SetENV = 'setenv';
export const EnvLoaded = 'envsetsuccess';
export const SelfiUploaded = 'selfieshow';
export const PlayerInfo = 'playerinfo';
export const AgoraEvent = 'agoraevent';
export const AgoraUsers = 'agoraUserList';
export const AgoraUserMute = 'agorausermute';
export const ShowVideoPlayer = 'videoevent';


export const SUCCESS_CODE = 200;

//action names
export const GESTURE = 'GESTURE';

export const ColorCombo = {
  a: 'rgb(102, 183, 99)',
  b: 'rgb(190, 192, 72)',
  c: 'rgb(198, 47, 46)',
  d: 'rgb(197, 46, 104)',
  e: 'rgb(175, 95, 197)',
  f: 'rgb(101, 46, 197)',
  g: 'rgb(46, 112, 197)',
  h: 'rgb(19, 130, 135)',
  i: 'rgb(9, 109, 45)',
  j: 'rgb(108, 111, 78)',
  k: 'rgb(111,78 , 78)',
  l: 'rgb(96, 78, 111)',
  m: 'rgb(78, 95, 111)',
  n: 'rgb(152, 198, 153)',
  o: 'rgb(197, 197, 99)',
  p: 'rgb(196, 197, 197)',
  q: 'rgb(197, 164, 99)',
  r: 'rgb(118, 23, 0)',
  s: 'rgb(49, 0, 118)',
  t: 'rgb(0, 118, 96)',
  u: 'rgb(12, 118, 99)',
  v: 'rgb(118, 110, 0)',
  w: 'rgb(78, 78, 0)',
  x: 'rgb(255, 130, 0)',
  y: 'rgb(231, 0, 255)',
  z: 'rgb(63, 90, 86)',
};

export const AvatarIcons = [
  'https://images.unsplash.com/photo-1566492031773-4f4e44671857?auto=format&fit=crop&q=80&w=3624&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&q=60&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YXZhdGFyfGVufDB8fDB8fHww',
  'https://images.unsplash.com/photo-1580489944761-15a19d654956?auto=format&fit=crop&q=80&w=3761&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&q=80&w=3648&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://plus.unsplash.com/premium_photo-1664541336816-2a2987114ca4?auto=format&fit=crop&q=80&w=3840&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  '',
];
export const HomePageData = [
  {
    section: 'Featured Games',
    games: [
      {
        id: 1,
        name: 'Clapis Towr Tomb Chaser Fighting Airbus',
        like: '1.6K',
        view: '107.1k',
        live: { currentWatch: 20 },
        watch: 10,
        productby: 'Astraverse',
        description: 'description dummy text',
        img: 'https://i.ytimg.com/vi/vbJE_XPcZKg/maxresdefault.jpg',
      },
      {
        id: 2,
        name: 'Tomb Chaser',
        like: 734,
        view: 345,
        productby: 'Protocol Gemini Studios',
        watch: 10,
        description:
          'Explore 4 new spooky zones in Punch Hero — an addictive idle clicker game where you break through enchanted walls, unlock new levels, and upgrade your power alongside dragons.Adventure awaits in the “Haunted Dragon Graveyard”, “The Witch’s Hideout”, “Lava Pits” and the “Pumpkin Zone” 👻 Harness your strength and set forth on an epic journey. Go from zero to hero!',
        img: 'https://www.cdmi.in/courses@2x/2D3D-Game-Design.webp',
        tag: [
          'survival',
          '3D',
          'running',
          'game',
          'fps',
          'shooty',
          'tset',
          'hello',
          'check',
          'survival',
          '3D',
          'running',
          'game',
          'fps',
          'shooty',
          'test',
        ],
      },
      {
        id: 3,
        name: 'Fighting',
        like: 453,
        view: 657,
        productby: 'Astraverse',
        description: 'description dummy text',
        img: 'https://i0.wp.com/awbi.org/wp-content/uploads/2023/02/Top-10-Sites-To-Download-Free-Pc-Games.jpg?fit=1280%2C720&ssl=1',
        tag: [
          'survival',
          '3D',
          'running',
          'game',
          'fps',
          'shooty',
          'tset',
          'hello',
          'check',
        ],
      },
      {
        id: 4,
        name: 'Airbus',
        like: 653,
        view: 345,
        productby: 'Astraverse',
        live: { currentWatch: 0 },
        description: 'description dummy text',
        img: 'https://www.91-cdn.com/hub/wp-content/uploads/2022/11/Play-cricket-game-on-Google-for-free.png',
        tag: ['survival', '3D', 'running'],
      },
      {
        id: 5,
        name: 'Titanic Day',
        like: 345,
        view: 674,
        productby: 'Protocol Gemini Studios',
        description: 'description dummy text',
        img: 'https://assets-prd.ignimgs.com/2020/09/24/top25xboxgames-slideshow-1600967273461.jpg',
      },
    ],
  },
  {
    section: 'Trending',
    games: [
      {
        id: 6,
        name: 'Punch Hero',
        like: 215,
        view: 435,
        productby: 'Maryana polycount',
        description: 'description dummy text',
        img: 'https://media.gq-magazine.co.uk/photos/645b5c3c8223a5c3801b8b26/16:9/w_2560%2Cc_limit/100-best-games-hp-b.jpg',
      },
      {
        id: 7,
        name: 'Shooty Shooty',
        like: 342,
        view: 345,
        productby: 'Trashhand',
        description: 'description dummy text',
        img: 'https://cdn2.unrealengine.com/26br-keyart-noshadow-1920x1080-3358aa3ee425.jpg',
      },
      {
        id: 8,
        name: 'Racing Empire',
        like: 234,
        view: 675,
        productby: 'Lemo',
        description: 'description dummy text',
        img: 'https://media.wired.com/photos/62855b1bb6cfd378a30c474a/master/pass/Build-Game-Watch-It-Die-Hyper-Scape-Games.jpg',
      },
      {
        id: 9,
        name: 'Clapis Towr',
        like: 346,
        view: 567,
        productby: 'Tamarin',
        description: 'description dummy text',
        img: 'https://i.ytimg.com/vi/vbJE_XPcZKg/maxresdefault.jpg',
      },
      {
        id: 10,
        name: 'Tomb Chaser',
        like: 734,
        view: 345,
        productby: 'Ira X Lab',
        description: 'description dummy text',
        img: 'https://www.cdmi.in/courses@2x/2D3D-Game-Design.webp',
      },
      {
        id: 11,
        name: 'Fighting',
        like: 453,
        view: 657,
        productby: 'Kijai',
        description: 'description dummy text',
        img: 'https://i0.wp.com/awbi.org/wp-content/uploads/2023/02/Top-10-Sites-To-Download-Free-Pc-Games.jpg?fit=1280%2C720&ssl=1',
      },
      {
        id: 12,
        name: 'Airbus',
        like: 653,
        view: 345,
        productby: 'Tommorow"s render',
        description: 'description dummy text',
        img: 'https://www.91-cdn.com/hub/wp-content/uploads/2022/11/Play-cricket-game-on-Google-for-free.png',
      },
      {
        id: 13,
        name: 'Titanic Day',
        like: 345,
        view: 674,
        productby: 'Meta verse Academy',
        description: 'description dummy text',
        img: 'https://assets-prd.ignimgs.com/2020/09/24/top25xboxgames-slideshow-1600967273461.jpg',
      },
      {
        id: 14,
        name: 'Punch Hero',
        like: 215,
        view: 435,
        productby: 'Maryana polycount',
        description: 'description dummy text',
        img: 'https://media.gq-magazine.co.uk/photos/645b5c3c8223a5c3801b8b26/16:9/w_2560%2Cc_limit/100-best-games-hp-b.jpg',
      },
      {
        id: 15,
        name: 'Shooty Shooty',
        like: 342,
        view: 345,
        productby: 'Trashhand',
        description: 'description dummy text',
        img: 'https://cdn2.unrealengine.com/26br-keyart-noshadow-1920x1080-3358aa3ee425.jpg',
      },
    ],
  },
  {
    section: 'Mind Blowing',
    games: [
      {
        id: 16,
        name: 'Punch Hero',
        like: 215,
        view: 435,
        productby: 'Maryana polycount',
        description: 'description dummy text',
        img: 'https://media.gq-magazine.co.uk/photos/645b5c3c8223a5c3801b8b26/16:9/w_2560%2Cc_limit/100-best-games-hp-b.jpg',
      },
      {
        id: 17,
        name: 'Shooty Shooty',
        like: 342,
        view: 345,
        productby: 'Trashhand',
        description: 'description dummy text',
        img: 'https://cdn2.unrealengine.com/26br-keyart-noshadow-1920x1080-3358aa3ee425.jpg',
      },
      {
        id: 18,
        name: 'Racing Empire',
        like: 234,
        view: 675,
        productby: 'Lemo',
        description: 'description dummy text',
        img: 'https://media.wired.com/photos/62855b1bb6cfd378a30c474a/master/pass/Build-Game-Watch-It-Die-Hyper-Scape-Games.jpg',
      },
      {
        id: 19,
        name: 'Clapis Towr',
        like: 346,
        view: 567,
        productby: 'Tamarin',
        description: 'description dummy text',
        img: 'https://i.ytimg.com/vi/vbJE_XPcZKg/maxresdefault.jpg',
      },
      {
        id: 20,
        name: 'Tomb Chaser',
        like: 734,
        view: 345,
        productby: 'Ira X Lab',
        description: 'description dummy text',
        img: 'https://www.cdmi.in/courses@2x/2D3D-Game-Design.webp',
      },
      {
        id: 21,
        name: 'Fighting',
        like: 453,
        view: 657,
        productby: 'Kijai',
        description: 'description dummy text',
        img: 'https://i0.wp.com/awbi.org/wp-content/uploads/2023/02/Top-10-Sites-To-Download-Free-Pc-Games.jpg?fit=1280%2C720&ssl=1',
      },
      {
        id: 22,
        name: 'Airbus',
        like: 653,
        view: 345,
        productby: 'Tommorow"s render',
        description: 'description dummy text',
        img: 'https://www.91-cdn.com/hub/wp-content/uploads/2022/11/Play-cricket-game-on-Google-for-free.png',
      },
      {
        id: 23,
        name: 'Punch Hero',
        like: 215,
        view: 435,
        productby: 'Maryana polycount',
        description: 'description dummy text',
        img: 'https://media.gq-magazine.co.uk/photos/645b5c3c8223a5c3801b8b26/16:9/w_2560%2Cc_limit/100-best-games-hp-b.jpg',
      },
      {
        id: 24,
        name: 'Shooty Shooty',
        like: 342,
        view: 345,
        productby: 'Trashhand',
        description: 'description dummy text',
        img: 'https://cdn2.unrealengine.com/26br-keyart-noshadow-1920x1080-3358aa3ee425.jpg',
      },
      {
        id: 25,
        name: 'Titanic Day',
        like: 345,
        view: 674,
        productby: 'Meta verse Academy',
        description: 'description dummy text',
        img: 'https://assets-prd.ignimgs.com/2020/09/24/top25xboxgames-slideshow-1600967273461.jpg',
      },
    ],
  },
  {
    section: 'Popular',
    games: [
      {
        id: 26,
        name: 'Punch Hero',
        like: 215,
        view: 435,
        productby: 'Maryana polycount',
        description: 'description dummy text',
        img: 'https://media.gq-magazine.co.uk/photos/645b5c3c8223a5c3801b8b26/16:9/w_2560%2Cc_limit/100-best-games-hp-b.jpg',
      },
      {
        id: 28,
        name: 'Shooty Shooty',
        like: 342,
        view: 345,
        productby: 'Trashhand',
        description: 'description dummy text',
        img: 'https://cdn2.unrealengine.com/26br-keyart-noshadow-1920x1080-3358aa3ee425.jpg',
      },
      {
        id: 27,
        name: 'Racing Empire',
        like: 234,
        view: 675,
        productby: 'Lemo',
        description: 'description dummy text',
        img: 'https://media.wired.com/photos/62855b1bb6cfd378a30c474a/master/pass/Build-Game-Watch-It-Die-Hyper-Scape-Games.jpg',
      },
      {
        id: 29,
        name: 'Clapis Towr',
        like: 346,
        view: 567,
        productby: 'Tamarin',
        description: 'description dummy text',
        img: 'https://i.ytimg.com/vi/vbJE_XPcZKg/maxresdefault.jpg',
      },
      {
        id: 30,
        name: 'Tomb Chaser',
        like: 734,
        view: 345,
        productby: 'Ira X Lab',
        description: 'description dummy text',
        img: 'https://www.cdmi.in/courses@2x/2D3D-Game-Design.webp',
      },
    ],
  },
  {
    section: 'Featured',
    games: [
      {
        id: 31,
        name: 'Clapis Towr Tomb Chaser Fighting Airbus',
        like: 215,
        view: 435,
        productby: 'Maryana polycount',
        description: 'description dummy text',
        video:
          'https://dd2cgqlmnwvp5.cloudfront.net/hero-carousel/JackHoney_AB-L_Spatial-10s.mp4',
      },
      {
        id: 32,
        name: 'Clapis Towr',
        like: 346,
        view: 567,
        productby: 'Tamarin',
        description: 'description dummy text',
        video:
          'https://dd2cgqlmnwvp5.cloudfront.net/hero-carousel/infinite ascent cropped version.mp4',
      },
      {
        id: 33,
        name: 'Tomb Chaser',
        like: 734,
        view: 345,
        productby: 'Ira X Lab',
        description: 'description dummy text',
        video:
          'https://dd2cgqlmnwvp5.cloudfront.net/hero-carousel/punch hero right ratio.mp4',
      },
    ],
  },
  {
    section: 'Featured Games',
    games: [
      {
        id: 34,
        name: 'Clapis Towr',
        like: 346,
        view: 567,
        productby: 'Tamarin',
        description: 'description dummy text',
        img: 'https://i.ytimg.com/vi/vbJE_XPcZKg/maxresdefault.jpg',
      },
      {
        id: 35,
        name: 'Tomb Chaser',
        like: 734,
        view: 345,
        productby: 'Ira X Lab',
        description: 'description dummy text',
        img: 'https://www.cdmi.in/courses@2x/2D3D-Game-Design.webp',
      },
      {
        id: 36,
        name: 'Fighting',
        like: 453,
        view: 657,
        productby: 'Kijai',
        description: 'description dummy text',
        img: 'https://i0.wp.com/awbi.org/wp-content/uploads/2023/02/Top-10-Sites-To-Download-Free-Pc-Games.jpg?fit=1280%2C720&ssl=1',
      },
      {
        id: 37,
        name: 'Airbus',
        like: 653,
        view: 345,
        productby: 'Tommorow"s render',
        description: 'description dummy text',
        img: 'https://www.91-cdn.com/hub/wp-content/uploads/2022/11/Play-cricket-game-on-Google-for-free.png',
      },
      {
        id: 38,
        name: 'Titanic Day',
        like: 345,
        view: 674,
        productby: 'Meta verse Academy',
        description: 'description dummy text',
        img: 'https://assets-prd.ignimgs.com/2020/09/24/top25xboxgames-slideshow-1600967273461.jpg',
      },
    ],
  },
  {
    section: 'Trending',
    games: [
      {
        id: 65,
        name: 'Punch Hero',
        like: 215,
        view: 435,
        productby: 'Maryana polycount',
        description: 'description dummy text',
        img: 'https://media.gq-magazine.co.uk/photos/645b5c3c8223a5c3801b8b26/16:9/w_2560%2Cc_limit/100-best-games-hp-b.jpg',
      },
      {
        id: 77,
        name: 'Shooty Shooty',
        like: 342,
        view: 345,
        productby: 'Trashhand',
        description: 'description dummy text',
        img: 'https://cdn2.unrealengine.com/26br-keyart-noshadow-1920x1080-3358aa3ee425.jpg',
      },
      {
        id: 67,
        name: 'Racing Empire',
        like: 234,
        view: 675,
        productby: 'Lemo',
        description: 'description dummy text',
        img: 'https://media.wired.com/photos/62855b1bb6cfd378a30c474a/master/pass/Build-Game-Watch-It-Die-Hyper-Scape-Games.jpg',
      },
      {
        id: 477,
        name: 'Clapis Towr',
        like: 346,
        view: 567,
        productby: 'Tamarin',
        description: 'description dummy text',
        img: 'https://i.ytimg.com/vi/vbJE_XPcZKg/maxresdefault.jpg',
      },
      {
        id: 672,
        name: 'Tomb Chaser',
        like: 734,
        view: 345,
        productby: 'Ira X Lab',
        description: 'description dummy text',
        img: 'https://www.cdmi.in/courses@2x/2D3D-Game-Design.webp',
      },
    ],
  },
  {
    section: 'Mind Blowing',
    games: [
      {
        id: 39,
        name: 'Shooty Shooty',
        like: 342,
        view: 345,
        productby: 'Trashhand',
        description: 'description dummy text',
        img: 'https://cdn2.unrealengine.com/26br-keyart-noshadow-1920x1080-3358aa3ee425.jpg',
      },
      {
        id: 40,
        name: 'Racing Empire',
        like: 234,
        view: 675,
        productby: 'Lemo',
        description: 'description dummy text',
        img: 'https://media.wired.com/photos/62855b1bb6cfd378a30c474a/master/pass/Build-Game-Watch-It-Die-Hyper-Scape-Games.jpg',
      },
      {
        id: 41,
        name: 'Clapis Towr',
        like: 346,
        view: 567,
        productby: 'Tamarin',
        description: 'description dummy text',
        img: 'https://i.ytimg.com/vi/vbJE_XPcZKg/maxresdefault.jpg',
      },
      {
        id: 42,
        name: 'Airbus',
        like: 653,
        view: 345,
        productby: 'Tommorow"s render',
        description: 'description dummy text',
        img: 'https://www.91-cdn.com/hub/wp-content/uploads/2022/11/Play-cricket-game-on-Google-for-free.png',
      },
      {
        id: 43,
        name: 'Tomb Chaser',
        like: 734,
        view: 345,
        productby: 'Ira X Lab',
        description: 'description dummy text',
        img: 'https://www.cdmi.in/courses@2x/2D3D-Game-Design.webp',
      },
    ],
  },
  {
    section: 'Popular',
    games: [
      {
        id: 44,
        name: 'Fighting',
        like: 453,
        view: 657,
        productby: 'Kijai',
        description: 'description dummy text',
        img: 'https://i0.wp.com/awbi.org/wp-content/uploads/2023/02/Top-10-Sites-To-Download-Free-Pc-Games.jpg?fit=1280%2C720&ssl=1',
      },
      {
        id: 45,
        name: 'Shooty Shooty',
        like: 342,
        view: 345,
        productby: 'Trashhand',
        description: 'description dummy text',
        img: 'https://cdn2.unrealengine.com/26br-keyart-noshadow-1920x1080-3358aa3ee425.jpg',
      },
      {
        id: 46,
        name: 'Airbus',
        like: 653,
        view: 345,
        productby: 'Tommorow"s render',
        description: 'description dummy text',
        img: 'https://www.91-cdn.com/hub/wp-content/uploads/2022/11/Play-cricket-game-on-Google-for-free.png',
      },
      {
        id: 47,
        name: 'Punch Hero',
        like: 215,
        view: 435,
        productby: 'Maryana polycount',
        description: 'description dummy text',
        img: 'https://media.gq-magazine.co.uk/photos/645b5c3c8223a5c3801b8b26/16:9/w_2560%2Cc_limit/100-best-games-hp-b.jpg',
      },
      {
        id: 48,
        name: 'Tomb Chaser',
        like: 734,
        view: 345,
        productby: 'Ira X Lab',
        description: 'description dummy text',
        img: 'https://www.cdmi.in/courses@2x/2D3D-Game-Design.webp',
      },
    ],
  },
];
