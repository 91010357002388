import { AvatarIcons } from '../../../constant';
import { AppData } from '../../../context';
import {
  GalleryIcon,
  InfoIcon,
  LikeIcon,
  MenuIcon,
  ShareIcon,
  UserIcon,
  VideoIcon,
} from '../../../svgIcons';
import useClickOutside from '../../../utils/hooks/useClickOutside';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import InteractionButtons from '../../CommonComponent/InteractionButtons';
import styles from './ActionButtonMenu.module.css';
import Tooltip from '../../CommonComponent/Tooltip';
import InteractionMenu from '../../CommonComponent/InteractionMenu';
import InfoModel from '../InfoModel/index';

const ActionButtonMenu = ({ envData }) => {
  const {
    showChat,
    setShowChat,
    kbdEventEnable,
    setShowGalleryModal,
    showUsersList,
    setShowUsersList,
    showVideoList,
    setShowVideoList,
    isUserJoined,
    userData,
  } = useContext(AppData);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const ref = useRef(null);
  const menuButtonRef = useRef(null);

  useEffect(() => {
    setShowChat(true);
  }, [setShowChat]);

  const onClickOutside = useCallback(() => {
    setShowSideMenu(false);
  }, []);

  useClickOutside(ref, onClickOutside, menuButtonRef);

  const handleChatClick = () => {
    setShowChat(!showChat);
  };

  const handleMenuClick = () => {
    setShowSideMenu(!showSideMenu);
  };

  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Escape' && kbdEventEnable) {
        setShowSideMenu(!showSideMenu);
      }
    };

    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [showSideMenu, kbdEventEnable]);

  const onGalleryClick = () => {
    setShowGalleryModal(true);
    console.warn('click');
  };

  const InfoClick = () => {};

  return (
    <>
      <InteractionMenu className={styles.menu_wrap}>
        <Tooltip tooltipText={'Info'}>
          <div onClick={() => setShowInfo(!showInfo)}>
            <InfoIcon />
          </div>
        </Tooltip>
        <Tooltip tooltipText={'Gallery'}>
          <div onClick={onGalleryClick}>
            <GalleryIcon />
          </div>
        </Tooltip>
        {isUserJoined && userData.is_admin && (
          <Tooltip tooltipText={'Users'}>
            <div onClick={() => setShowUsersList(!showUsersList)}>
              <UserIcon />
            </div>
          </Tooltip>
        )}
        {isUserJoined && userData.is_admin && (
          <Tooltip tooltipText={'Videos'}>
            <div onClick={() => setShowVideoList(!showVideoList)}>
              <VideoIcon />
            </div>
          </Tooltip>
        )}
        {/* <Tooltip tooltipText={showChat ? 'Hide chat' : 'Show chat'}>
          <div onClick={handleChatClick}>
            {showChat ? <HideChatIcon /> : <ShowChatIcon />}
          </div>
        </Tooltip> */}
        <Tooltip tooltipText={'Menu'}>
          <div onClick={handleMenuClick} ref={menuButtonRef}>
            <MenuIcon />
          </div>
        </Tooltip>
      </InteractionMenu>
      {showInfo && <InfoModel setShowInfo={setShowInfo} />}
      {showSideMenu && (
        <div ref={ref}>
          <SideMenu envDetails={envData} />
        </div>
      )}
    </>
  );
};

const SideMenu = ({ envDetails = {} }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  // const { setActionName } = useContext(AppData);

  // const handleGestureClick = () => {
  //   setActionName(GESTURE);
  // };

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <div className={styles.side_menu_wrap}>
      <div className={styles.env_details}>
        <div className={styles.user_details_wrap}>
          <div className={styles.user_details}>
            {/* <img width={16} height={16} src={AvatarIcons[0]} alt="User" /> */}
            <div>{envDetails.creatorName}</div>
          </div>
          <div className={styles.interaction_button}>
            <div>
              <LikeIcon />
            </div>
            <div>
              <ShareIcon />
            </div>
          </div>
        </div>
        <div className={styles.env_title}>Panchvati Yuva Group</div>
        <div className={styles.env_stats}>
          {/* <InteractionButtons
            envDetails={envDetails}
            handleEnvClick={() => {}}
          /> */}
        </div>
        {/* <div className={styles.button_group}> */}
        {/* <div className={styles.button_wrap} onClick={handleGestureClick}>
            <GestureIcon />
            <KBDButton className={styles.kbd_key}>T</KBDButton>
          </div> */}
        {/* </div> */}
        {/* <div
          className={styles.env_description}
          onClick={() => setIsShowMore(!isShowMore)}
        >
          {envDetails.description.length > 115
            ? isShowMore
              ? envDetails.description
              : envDetails.description.substring(0, 115) + '...'
            : envDetails.description}
        </div> */}
        {envDetails.description.length > 115 && (
          <button
            className={styles.show_more_button}
            onClick={() => setIsShowMore(!isShowMore)}
          >
            {isShowMore ? 'Show less' : 'Show more'}
          </button>
        )}
      </div>
      <div className={styles.menu_items}>
        {/* <Link href={'/support'} target={'_blank'}>
          <div>Help</div>
        </Link> */}
        <div onClick={handleLogout}>
          <div>Leave</div>
        </div>
      </div>
    </div>
  );
};

export default ActionButtonMenu;
