import React, { useContext, useState } from 'react';
import './CustomUploader.css';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import { AppData } from '../../../context';
import { uploadFile } from '../../../services';
import { toast } from 'react-toastify';

const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
  const { uploadModalData, setUploadModalData } = useContext(AppData);
  const [errorText, setErrorText] = useState('');
  const text = 'Select From Your Device';

  return (
    <>
      {errorText && <div className={'upload_error_text'}>{errorText}</div>}
      <div className="upload_text_1">Drag files here</div>
      <div className="upload_text_2">Upload images or videos! </div>
      <div className="upload_text_3">or </div>
      <label className="upload_button">
        {text}
        <input
          style={{ display: 'none' }}
          type="file"
          accept={accept}
          multiple
          onChange={e => {
            getFilesFromEvent(e).then(chosenFiles => {
              const file = chosenFiles[0];
              const reader = new FileReader();
              reader.onload = () => {
                console.warn(`File loaded locally - uploading`, reader.result);

                console.warn('sending data', file.type, file.size / 1024 < 10);
                let isImage = file.type.indexOf('image') > -1;
                let allowedMediaTypes = [
                  'image/jpeg',
                  'image/png',
                  'video/mp4',
                ];
                let isMediaTypeMatched = allowedMediaTypes.includes(file.type);
                if (
                  (isImage && file.size / 1024 / 1024 < 10) ||
                  (!isImage &&
                    file.size / 1024 / 1024 < 50 &&
                    isMediaTypeMatched)
                ) {
                  setErrorText('');
                  setUploadModalData({
                    ...uploadModalData,
                    mediaUrl: URL.createObjectURL(chosenFiles[0]),
                    type: isImage ? 'image' : 'video',
                    file: chosenFiles[0],
                    mediaUploaded: true,
                  });
                } else {
                  let errorText = 'Please upload JPG or PNG image';
                  if (isMediaTypeMatched) {
                    if (!isImage) {
                      errorText = 'Please upload MP4 video';
                    }
                  } else {
                    if (isImage) {
                      errorText = 'Please upload image under 10mb size';
                    } else {
                      errorText = 'Please upload video under 50mb size';
                    }
                  }
                  setErrorText(errorText);
                }
              };
              reader.readAsArrayBuffer(file);
              console.warn(
                'chosenFiles',
                chosenFiles,
                URL.createObjectURL(chosenFiles[0]),
              );
              onFiles(chosenFiles);
            });
          }}
        />
      </label>
    </>
  );
};

const MediaPreviewer = ({ url }) => {
  const { uploadModalData, setUploadModalData } = useContext(AppData);
  const [loading, setLoading] = useState(false);
  const [changeDetails, setChangeDetails] = useState(false);
  const [data, setData] = useState(uploadModalData);
  const mediaType = uploadModalData.type;

  const onReplaceImage = () => {
    setUploadModalData({ ...uploadModalData, mediaUrl: '' });
  };
  console.info('uploadModalData', JSON.stringify(uploadModalData));
  const onDeleteImage = () => {
    setUploadModalData({
      ...uploadModalData,
      mediaUrl: '',
      mediaChanged: true,
    });
  };

  const onMediaChange = () => {
    setChangeDetails(true);
  };

  const handleChange = (label, e) => {
    setData({ ...data, [label]: e.target.value });
  };

  const handleSave = async () => {
    setLoading(true);
    let bodyFormData = new FormData();
    let body = {
      environmentid: uploadModalData.envId,
      placementid: uploadModalData.id,
      slug: uploadModalData.slug,
      title: data.title,
      description: data.description,
      redirectlink: data.redirectlink,
      type: uploadModalData.type,
    };

    if (uploadModalData.file) {
      body = { ...body, file: uploadModalData.file };
    }
    for (var key in body) {
      bodyFormData.append(key, body[key]);
    }
    await uploadFile(
      bodyFormData,
      () => {
        setUploadModalData({ ...uploadModalData, ...data, mediaChanged: true });
      },
      err => {
        console.log('file upload errror', err);
        toast.error(`We are facing error. please try after some time`, {
          position: 'top-right',
          autoClose: 3000,
          theme: 'dark',
        });
      },
    );
  };

  return (
    <div>
      <div className="uploader_content">
        <div>
          {uploadModalData.type === 'image' ? (
            <img
              width={1000}
              height={window.innerHeight - 200}
              src={url}
              alt="User"
              className="media_container"
            />
          ) : (
            <video height={window.innerHeight - 200} autoPlay muted loop>
              <source src={url} type="video/webm" />
            </video>
          )}
        </div>
        {(uploadModalData.mediaUploaded || changeDetails) && (
          <div className={'upload_media_details'}>
            <div className={'upload_media_title'}>Media Details</div>
            <div className="upload_details_label">Title</div>
            <input
              className="upload_details_field"
              value={data.title}
              onChange={e => handleChange('title', e)}
            />
            <div className="upload_details_label">Description</div>
            <textarea
              className="upload_details_field"
              value={data.description}
              onChange={e => handleChange('description', e)}
            />
            <div className="upload_details_label">Link</div>
            <input
              className="upload_details_field"
              value={data.redirectlink}
              placeholder="https://exapmle.com"
              onChange={e => handleChange('redirectlink', e)}
            />
          </div>
        )}
      </div>
      <div className="upload_button_wrap">
        {uploadModalData.mediaUploaded || changeDetails ? (
          <button className="upload_button" onClick={handleSave}>
            {' '}
            {changeDetails
              ? 'Save details'
              : uploadModalData.type === 'image'
                ? 'Upload image'
                : 'Upload video'}
          </button>
        ) : (
          <>
            <button className="upload_button" onClick={onMediaChange}>
              Change {mediaType} details
            </button>
            <button className="upload_button" onClick={onReplaceImage}>
              Replace {mediaType}
            </button>
            <button
              className="upload_button delete_button"
              onClick={onDeleteImage}
            >
              Delete {mediaType}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const MediaUploader = () => {
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach(f => f.remove());
  };

  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject));
      });
    });
  };
  return (
    <Dropzone
      accept="image/*,video/*,"
      getUploadParams={() => ({ url: 'https://httpbin.org/post' })}
      onSubmit={handleSubmit}
      InputComponent={Input}
      getFilesFromEvent={getFilesFromEvent}
      addClassNames={{ dropzone: 'uploader_wrap' }}
      multiple={false}
      PreviewComponent={null}
      SubmitButtonComponent={null}
    />
  );
};

const detailsFields = () => {
  return (
    <div>
      <div className="upload_details_label">Title</div>
      <input className="upload_details_field" />
    </div>
  );
};

const CustomUploader = () => {
  const { uploadModalData } = useContext(AppData);

  return uploadModalData.mediaUrl ? (
    <MediaPreviewer url={uploadModalData.mediaUrl} />
  ) : (
    <MediaUploader />
  );
};

export default CustomUploader;
